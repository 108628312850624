import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../delete/components/Button';
import Sales from '../../../service/Sales';

const DeleteModal = ({
  setDeleteModalOn,
  deleteIdx,
  setShowModal,
  setChangeYN,
  changeYN,
}: any) => {
  const [showDeletePopUp2, setShowDeletePopUp2] = useState(false);

  const [checkedStates, setCheckedStates] = useState(9999);
  const [selectedReasons, setSelectedReasons] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(''); // 최종 업데이트되는 입력 값

  const [state, setState] = useState({
    reason: '',
    password: '',
  });
  const [showInput, setShowInput] = useState(false);
  const [focused, setFocused] = useState(false);

  const handleFocusBlur = (isFocused: any) => {
    setFocused(isFocused);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue); // 0.3초 동안 입력이 없으면 업데이트
    }, 300);

    // 이전 타이머를 정리
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (checkedStates === 4 && debouncedValue) {
      setSelectedReasons(`기타: ${debouncedValue}`);
    }
  }, [debouncedValue, checkedStates]);

  const handleCheckboxClick = (index: number) => {
    // const newCheckedStates = [...checkedStates];
    // newCheckedStates[index] = !newCheckedStates[index];
    setCheckedStates(index);

    if (index === 4) {
      setShowInput(true); // "기타" 입력창 제어
    } else {
      const reasons = [
        '거래완료',
        '등록 삭제',
        '삭제 후 재등록',
        '서비스 불만',
        '기타',
      ];
      setSelectedReasons(reasons[index]);
    }
  };

  const itemDelete = async (sale_idx: string, delete_reson: string) => {
    const res = await Sales.salesItemDelete(sale_idx, delete_reson);
    return res.data;
  };

  return (
    <div
      id="post-meta-col"
      className="flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      onClick={() => {
        setDeleteModalOn(false);
      }}
    >
      {showDeletePopUp2 ? (
        <div
          className="bg-FFFFFF padding-24-16 border-radius-16 gap-12 w-312 flex-center flex-column"
          onClick={(e) => {
            e.stopPropagation(); // 이벤트 전파 방지
          }}
        >
          <div className="flex-column w-100per">
            <PageTop>
              <img
                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                src="/asset/images/close/close-darkgray.svg"
                alt=""
                onClick={() => {
                  setDeleteModalOn(false);
                }}
              />
            </PageTop>
            <div
              className="flex-column w-100per h-100per"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px',
                paddingBottom: '24px',
              }}
            >
              <div>
                <img src="/asset/images/icon/trash_can.svg" alt="" />
              </div>
              <DeleteTextBox>
                <div>
                  등록된 매물을 <span>삭제</span>하시겠어요?
                </div>
                <DeleteText>등록된 매물이 목록에서 사라져요</DeleteText>
              </DeleteTextBox>
            </div>
            <div className="p flex w-100per gap-8">
              <Button
                type="deleteModal"
                description="취소"
                onClick={() => {
                  setDeleteModalOn(false);
                }}
              />
              <Button
                on={true}
                description="네"
                onClick={() => {
                  itemDelete(deleteIdx, selectedReasons);
                  setDeleteModalOn(false);
                  setShowModal({ status: true, text: '삭제완료' });
                  setChangeYN(!changeYN);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="bg-FFFFFF padding-24-16 border-radius-16 gap-12 w-312 flex-center flex-column"
          onClick={(e) => {
            e.stopPropagation(); // 이벤트 전파 방지
          }}
        >
          <div className="flex-column w-100per">
            <PageTop>
              <img
                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                src="/asset/images/close/close-darkgray.svg"
                alt=""
                onClick={() => {
                  setDeleteModalOn(false);
                }}
              />
            </PageTop>
            <div className="flex-column gap-8 flex-center">
              <div className="m-b1-16-b">삭제 사유 선택</div>
            </div>
            <div className="flex-column w-100per h-100per">
              <div
                className="box2"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '16px',
                  gap: '16px',
                  borderRadius: '8px',
                  height: '100%',
                  paddingBottom: '24px',
                }}
              >
                <div
                  className="box2Text"
                  style={{ display: 'flex', gap: '8px' }}
                  onClick={() => handleCheckboxClick(0)}
                >
                  <img
                    src={`/asset/images/icon/${checkedStates === 0 ? 'select_box_on.svg' : 'select_box_off.svg'}`}
                    alt=""
                  />{' '}
                  거래완료
                </div>
                <div
                  className="box2Text"
                  style={{ display: 'flex', gap: '8px' }}
                  onClick={() => handleCheckboxClick(1)}
                >
                  <img
                    src={`/asset/images/icon/${checkedStates === 1 ? 'select_box_on.svg' : 'select_box_off.svg'}`}
                    alt=""
                  />{' '}
                  등록 삭제
                </div>
                <div
                  className="box2Text"
                  style={{ display: 'flex', gap: '8px' }}
                  onClick={() => handleCheckboxClick(2)}
                >
                  <img
                    src={`/asset/images/icon/${checkedStates === 2 ? 'select_box_on.svg' : 'select_box_off.svg'}`}
                    alt=""
                  />{' '}
                  삭제 후 재등록
                </div>
                <div
                  className="box2Text"
                  style={{ display: 'flex', gap: '8px' }}
                  onClick={() => handleCheckboxClick(3)}
                >
                  <img
                    src={`/asset/images/icon/${checkedStates === 3 ? 'select_box_on.svg' : 'select_box_off.svg'}`}
                    alt=""
                  />{' '}
                  서비스 불만
                </div>
                <div
                  className="box2Text"
                  style={{ display: 'flex', gap: '8px' }}
                  onClick={() => handleCheckboxClick(4)}
                >
                  <img
                    src={`/asset/images/icon/${checkedStates === 4 ? 'select_box_on.svg' : 'select_box_off.svg'}`}
                    alt=""
                  />{' '}
                  기타
                </div>
                {showInput && (
                  <input
                    className={`customdelete-input ${focused ? 'focused' : ''}`}
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Pretendard-medium',
                      fontWeight: '500',
                      lineHeight: '20px',
                    }}
                    onFocus={() => handleFocusBlur(true)}
                    onBlur={() => handleFocusBlur(false)}
                    type="text"
                    placeholder="다른 이유가 있다면 알려주세요."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                )}
              </div>
            </div>

            <div className="p flex w-100per gap-8">
              <Button
                type="deleteModal"
                description="취소"
                onClick={() => {
                  setDeleteModalOn(false);
                }}
              />
              <Button
                on={selectedReasons.length > 0}
                description="다음"
                onClick={() => {
                  if (selectedReasons.length === 0) {
                    alert('삭제 사유를 선택하셔야 합니다.');
                  } else {
                    const updatedSelectedReasons = [selectedReasons];

                    // 기존 '기타' 항목 제거
                    const otherReasonIndex = updatedSelectedReasons.findIndex(
                      (reason) => reason.startsWith('기타:'),
                    );
                    if (otherReasonIndex !== -1) {
                      updatedSelectedReasons.splice(otherReasonIndex, 1);
                    }

                    if (checkedStates === 4 && debouncedValue) {
                      const otherReason = `기타: ${debouncedValue}`;
                      // '기타'가 이미 선택되어 있다면 중복 방지
                      if (!updatedSelectedReasons.includes(otherReason)) {
                        updatedSelectedReasons.push(otherReason);
                      }
                    }

                    // 최종적으로 setSelectedReasons로 업데이트하고 팝업 표시
                    setSelectedReasons(selectedReasons);
                    setShowDeletePopUp2(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeleteModal;

const DeleteText = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const DeleteTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;

  color: black;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  span {
    color: rgba(30, 155, 107, 1);
  }
`;

const Inputbox = styled.input`
  display: flex;
  background-color: rgba(250, 250, 250, 1);
  font-size: 17px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26px;
  color: black;
  border: none;
  width: ${(props) => `${props.width}px` || '100%'};
  &::placeholder {
    font-family: Pretendard-medium;
    line-height: 30px;
    color: rgba(136, 139, 144, 1);
    font-size: 17px;
  }
`;
const handleKeyDown = (event: any) => {
  if (event.keyCode === 32) {
    event.preventDefault();
  }
};

const VerifyBtn = styled.div`
  display: flex;
  background-color: #ededef;
  width: 98px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-family: 'SpoqaHanSansNeoMedium';
  font-size: 13px;
  line-height: 16px;
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const PhoneNumberText = styled.div`
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const PhoneNumber = styled.div`
  color: rgba(55, 56, 60, 0.28);
  font-size: 17px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
`;

const PageTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
