import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../color';
import { useEffect, useState } from 'react';
import SaleItemFilter from './components/SaleItemFilter';
import SaleItemRegionModal from './components/SaleItemRegionModal';

import {
  SelectedOptionContainer,
  ResetBtn,
  SelecteOptionBtn,
  SelecteOptionBtnText,
} from '../property/Property.styles';
import SelectTypeBox from './components/SelectTypeBox';
import SaleItemList from './components/SaleItemList';
import DeleteModal from './components/DeleteModal';
import Sales from '../../service/Sales';
import Loading from '../components/loading/Loading';

const initialFilterOption = {
  sale: {
    itemType: ['토지', '주택'],
    saleStatus: ['공개', '비공개', '거래완료'],
  },
  regionAddress: {
    sido: '',
    sigungu: '',
    eupmd: '',
    ri: '',
    pnu: '',
  },
  sort: 'D',
};

const MyItems = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [hasRegionChanged, setHasRegionChanged] = useState(false);
  const [filterModalOn, setFilterModalOn] = useState(false);
  const [regionModalOn, setRegionModalOn] = useState(false);
  const [saleItemOption, setSaleItemOption] = useState({
    itemType: ['토지', '주택'],
    saleStatus: ['공개', '비공개', '거래완료'],
  });
  const [regionAddress, setRegionAddress] = useState({
    sido: '',
    sigungu: '',
    eupmd: '',
    ri: '',
    pnu: '',
  });
  const [saleItemSort, setSaleItemSort] = useState('D');
  const [isInitialized, setIsInitialized] = useState(false);
  const [saleItemFilterOption, setSaleItemFilterOption] = useState({
    sale: {
      itemType: ['토지', '주택'],
      saleStatus: ['공개', '비공개', '거래완료'],
    },
    regionAddress: {
      sido: '',
      sigungu: '',
      eupmd: '',
      ri: '',
      pnu: '',
    },
    sort: 'D',
  });

  const [itemList, setItemList] = useState<any[]>([]); // 임시로 any[] 사용
  const [showModal, setShowModal] = useState({
    status: false,
    text: '',
  });
  const [DeleteModalOn, setDeleteModalOn] = useState(false);
  const [changeYN, setChangeYN] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState(0);
  const [lastIdx, setLastIdx] = useState(0);
  const [nextList, setNextList] = useState<any[]>([]); // 더보기 리스트
  const [hasMore, setHasMore] = useState<boolean>(false); // 더보기 버튼 활성화 여부
  useEffect(() => {
    if (!localStorage.getItem('filterOption')) {
      localStorage.setItem(
        'filterOption',
        JSON.stringify({
          ...saleItemFilterOption,
        }),
      );
    } else {
      const existingFilterOption = JSON.parse(
        localStorage.getItem('filterOption') || '{}',
      );
      setSaleItemOption(existingFilterOption.sale);
      setRegionAddress(existingFilterOption.regionAddress);
      setSaleItemSort(existingFilterOption.sort);
    }
    setIsInitialized(true);
  }, []);

  const getList = async (
    last_idx: number,
    sort: string,
    pnu: string,
    type: string[],
    state: string[],
  ) => {
    setLoading(true);
    const getTypeValue = (types: string[]): string => {
      const mapping: { [key: string]: string } = {
        토지: 'L',
        주택: 'H',
      };

      // 배열의 각 값을 매핑에 따라 변환하고, 콤마로 결합
      return types.map((item) => mapping[item]).join(',');
    };

    const getStateValue = (types: string[]): string => {
      const mapping: { [key: string]: string } = {
        공개: 'Y',
        비공개: 'N',
        거래완료: 'T',
      };

      // 배열의 각 값을 매핑에 따라 변환하고, 콤마로 결합
      return types.map((item) => mapping[item]).join(',');
    };
    try {
      const res = await Sales.mySaleItemList(
        last_idx,
        sort,
        pnu,
        getTypeValue(type),
        getStateValue(state),
      );
      const result = res.result;
      setLoading(false);
      return result;
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      return [];
    }
  };

  const initialLoad = async (
    last_idx: number,
    sort: string,
    pnu: string,
    type: string[],
    state: string[],
  ) => {
    const list1 = await getList(last_idx, sort, pnu, type, state);
    setItemList(list1); // list1만 설정

    if (list1.length > 0) {
      const list2 = await getList(
        list1[list1.length - 1].sale_idx,
        sort,
        pnu,
        type,
        state,
      ); // list1의 마지막 매물번호로 list2 조회
      if (list2.length === 0) {
        setHasMore(false); // 더 이상 데이터 없음
      } else {
        setHasMore(true);
        setNextList(list2); // list2를 별도로 저장
        setLastIdx(list2[list2.length - 1].sale_idx); // lastIdx를 list2의 마지막 매물번호로 설정
      }
    } else {
      setHasMore(false); // list1의 길이가 0인 경우 더보기 버튼 비활성화
    }
  };

  // 더보기 버튼 클릭 시
  const handleLoadMore = async () => {
    console.log('handleLoadMore', nextList);
    if (nextList.length > 0) {
      setItemList((prev) => [...prev, ...nextList]); // 기존 리스트와 nextList를 결합
      // fetchNextList(); // 다음 리스트를 가져오기
      const newList = await getList(
        lastIdx,
        saleItemSort,
        regionAddress.pnu,
        saleItemOption.itemType,
        saleItemOption.saleStatus,
      );
      if (newList.length === 0) {
        setHasMore(false); // 더 이상 데이터 없음
      } else {
        setHasMore(true); // 더보기 버튼 활성화
        setNextList(newList); // 다음 리스트를 nextList로 설정
        setLastIdx(newList[newList.length - 1].sale_idx);
      }
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    setSaleItemFilterOption({
      sale: saleItemOption,
      regionAddress: regionAddress,
      sort: saleItemSort,
    });

    initialLoad(
      0, //필터 값이 변경되면 0으로 초기화
      saleItemSort,
      regionAddress.pnu,
      saleItemOption.itemType,
      saleItemOption.saleStatus,
    );

    // 배열 비교 함수
    const ArrayEqual = (type: 'itemType' | 'saleStatus') => {
      const Arr1 = [...saleItemOption[type]].sort();
      const Arr2 = [...initialFilterOption.sale[type]].sort();
      if (JSON.stringify(Arr1) === JSON.stringify(Arr2)) {
        return true;
      } else {
        return false;
      }
    };
    //매물관리 필터 비교
    if (ArrayEqual('itemType') && ArrayEqual('saleStatus')) {
      setHasFilterChanged(false);
    } else {
      setHasFilterChanged(true);
    }

    if (
      regionAddress.sido === '' &&
      regionAddress.sigungu === '' &&
      regionAddress.eupmd === '' &&
      regionAddress.ri === ''
    ) {
      setHasRegionChanged(false);
    } else {
      setHasRegionChanged(true);
    }
  }, [saleItemOption, regionAddress, saleItemSort, changeYN]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (saleItemFilterOption) {
      localStorage.setItem(
        'filterOption',
        JSON.stringify({ ...saleItemFilterOption }),
      );
    }
  }, [saleItemFilterOption]);

  const renderFilter = () => {
    if (hasRegionChanged) {
      return (
        <SelectedOptionContainer
          style={{
            border: 'none',
            paddingTop: '10px',
            paddingBottom: '16px',
            paddingLeft: '0px',
            paddingRight: '0px',
          }}
        >
          <ResetBtn
            onClick={() => {
              setRegionAddress({
                sido: '',
                sigungu: '',
                eupmd: '',
                ri: '',
                pnu: '',
              });
            }}
          >
            <img src="/asset/images/icon/reset.svg" alt="" />
          </ResetBtn>
          <SelecteOptionBtn>
            <SelecteOptionBtnText>{`${regionAddress.sigungu}`}</SelecteOptionBtnText>
            <img
              src="/asset/images/arrow/arrow-down.svg"
              alt=""
              onClick={() => {
                setRegionModalOn(true);
              }}
            />
          </SelecteOptionBtn>

          <SelecteOptionBtn>
            <SelecteOptionBtnText>
              {regionAddress.eupmd === ''
                ? '읍/면/동/리'
                : `${regionAddress.eupmd}`}
            </SelecteOptionBtnText>
            <img
              src="/asset/images/arrow/arrow-down.svg"
              alt=""
              onClick={() => {
                setRegionModalOn(true);
              }}
            />
          </SelecteOptionBtn>
        </SelectedOptionContainer>
      );
    } else {
      return (
        <FilterContainer>
          <FilterTab
            onClick={() => {
              setRegionModalOn(true);
            }}
          >
            <FilterTabText>지역</FilterTabText>
            <img src="/asset/images/arrow/arrow-down.svg" alt="" />
          </FilterTab>
        </FilterContainer>
      );
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (showModal.status) {
      setTimeout(() => {
        setShowModal({ status: false, text: '' });
      }, 1000);
    }
  }, [showModal]);

  return (
    <Container>
      <TitleBox>
        <Title>매물관리</Title>
        <img
          src={`/asset/images/icon/${hasFilterChanged ? 'filterOn' : 'filter'}.svg`}
          alt=""
          onClick={() => {
            setFilterModalOn(true);
          }}
          style={{ cursor: 'pointer' }}
        />
      </TitleBox>
      <FilterBox>
        {renderFilter()}
        <SortBox>
          <SelectTypeBox
            value={saleItemSort}
            onChange={setSaleItemSort}
            type={'saleItem'}
          ></SelectTypeBox>
        </SortBox>
      </FilterBox>
      {itemList.length === 0 ? (
        <NoItemWrapper>
          <NoItemText>
            <div>등록된 매물이 없어요</div>
            <div>
              <span>매물을 등록해보세요</span>
            </div>
          </NoItemText>
          <Button
            onClick={() => {
              navigate('/register/property');
            }}
          >
            매물 등록하기
          </Button>
        </NoItemWrapper>
      ) : (
        <SaleItemList
          itemList={itemList}
          setItemList={setItemList}
          setShowModal={setShowModal}
          setDeleteIdx={setDeleteIdx}
          setDeleteModalOn={setDeleteModalOn}
          changeYN={changeYN}
          setChangeYN={setChangeYN}
          hasMore={hasMore}
          handleLoadMore={handleLoadMore}
        ></SaleItemList>
      )}
      {filterModalOn && (
        <SaleItemFilter
          setFilterModalOn={setFilterModalOn}
          setSaleItemOption={setSaleItemOption}
          setShowModal={setShowModal}
        />
      )}
      {regionModalOn && (
        <SaleItemRegionModal
          setRegionModalOn={setRegionModalOn}
          setRegionAddress={setRegionAddress}
          regionAddress={regionAddress}
        />
      )}
      {showModal.status && <ModalBox>{showModal.text}</ModalBox>}
      {DeleteModalOn && (
        <DeleteModal
          deleteIdx={deleteIdx}
          setDeleteModalOn={setDeleteModalOn}
          setShowModal={setShowModal}
          changeYN={changeYN}
          setChangeYN={setChangeYN}
        />
      )}
      {loading && <Loading />}
    </Container>
  );
};

export default MyItems;

const ModalBox = styled.div`
  position: sticky;
  bottom: 70px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  background: rgba(80.73, 84.51, 86.19, 0.92);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: 1px #515556 solid;
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  color: white;
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  z-index: 11;
`;

const Button = styled.div`
  padding: 8px 16px;
  color: #1e9b6b;
  font-weight: 500;
  border: 1px #1e9b6b solid;
  border-radius: 8px;
  cursor: pointer;
`;

const NoItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  word-wrap: break-word;
  span {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
  }
`;

const NoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px 0px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
`;

const SortBox = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 16px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    white 25%
  ) !important;
`;

const FilterTab = styled.div`
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNeutral};
  cursor: pointer;
`;

const FilterTabText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: ${colors.labelNeutral};
  line-height: 20px;
  white-space: nowrap;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  overflow-x: auto;
  padding: 16px 0px;
  padding-top: 10px;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const FilterBox = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: white;
`;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  background-color: white;
  flex-grow: 1;
  margin-bottom: 54px;
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.span`
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
