import styled from 'styled-components';

interface ButtonProps {
  text: string;
  onClick: () => void;
  bottom?: number;
}

const BottomButtonBox = ({ text, onClick, bottom }: ButtonProps) => {
  return (
    <ButtonBox bottom={bottom}>
      <GreenBox
        style={{
          color: '#ffffff',
          backgroundColor: '#1E9B6B',
        }}
        onClick={onClick}
      >
        {text}
      </GreenBox>
    </ButtonBox>
  );
};

export default BottomButtonBox;

interface ButtonBoxProps {
  bottom?: number;
}

export const ButtonBox = styled.div<ButtonBoxProps>`
  width: 100%;
  position: fixed;
  bottom: ${(props) => (props.bottom ? `${props.bottom}px` : '0')};
  z-index: 10;
  padding: 20px 16px 24px;
  max-width: inherit;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 16.49%);
`;
export const GreenBox = styled.button`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;
