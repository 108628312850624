import styled from 'styled-components';
import {
  formatToKoreanCurrency,
  transNum,
} from '../../../../function/function';
import UnitSwitch from '../../../components/unitSwitch/UnitSwitch';
import { useState } from 'react';
import { SummaryProps } from '../../data';
import SummaryTitle from './components/SummaryTitle';
import SummaryCategory from './components/SummaryCategory';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../color';

interface TextColorProps {
  color: string;
  backgroundColor: string;
}

interface PriceColorProps {
  priceColor: string;
}

interface SaleDateProps {
  fontColor: string;
  fontWeight: number;
}

interface DividerProps {
  height: number;
}

interface SummaryDataProps {
  data: SummaryProps;
  approvalYear?: string;
  roadSurface?: string;
}

const Summary = (data: SummaryDataProps) => {
  const [etcAddressModal, setEtcAddressModal] = useState(false);
  const [unit, setUnit] = useState('평');
  const navigate = useNavigate();
  const PriceColor = (price: string): PriceColorProps => {
    switch (price) {
      case 'minPrice':
        return { priceColor: '#EC4F4F' };
      case 'appraisedPrice':
        return { priceColor: 'black' };
      default:
        return { priceColor: 'black' };
    }
  };

  const SaleDateFont = (contentType: string): SaleDateProps => {
    switch (contentType) {
      case 'saleDate':
        return { fontColor: '#222222', fontWeight: 700 };
      case 'D_Day':
        return { fontColor: '#1E9B6B', fontWeight: 500 };
      default:
        return { fontColor: '#616161', fontWeight: 500 };
    }
  };

  const DividerHeight = (dataType: string): DividerProps => {
    switch (dataType) {
      case 'auction':
        return { height: 24 };
      case 'summary':
        return { height: 8 };
      default:
        return { height: 8 };
    }
  };

  const renderPrice = (unit: string, itemType: string) => {
    if (itemType === 'house') {
      if (unit === '평') {
        return (
          <>
            <div>{transNum(data.data.housePyeong, false, false)}평</div>
            {data.data.housePricePerPyeong &&
            data.data.housePricePerPyeong !== 0 ? (
              <>
                <ContentDivider
                  height={DividerHeight('summary').height}
                ></ContentDivider>
                <div>{transNum(data.data.housePricePerPyeong)}원/1평</div>
              </>
            ) : null}
          </>
        );
      } else {
        return (
          <>
            <div>{transNum(data.data.houseArea, false, false)}m²</div>
            {data.data.housePricePerMeter &&
            data.data.housePricePerMeter !== 0 ? (
              <>
                <ContentDivider
                  height={DividerHeight('summary').height}
                ></ContentDivider>
                <div>{transNum(data.data.housePricePerMeter)}원/m²</div>
              </>
            ) : null}
          </>
        );
      }
    } else {
      if (unit === '평') {
        return (
          <>
            <div>{transNum(data.data.pyeong, false, false)}평</div>
            {data.data.pricePerPyeong && data.data.pricePerPyeong !== 0 ? (
              <>
                <ContentDivider
                  height={DividerHeight('summary').height}
                ></ContentDivider>
                <div>{transNum(data.data.pricePerPyeong)}원/m²</div>
              </>
            ) : null}
          </>
        );
      } else {
        return (
          <>
            <div>{transNum(data.data.area, false, false)}m²</div>
            {data.data.pricePerMeter && data.data.pricePerMeter !== 0 ? (
              <>
                <ContentDivider
                  height={DividerHeight('summary').height}
                ></ContentDivider>
                <div>{transNum(data.data.pricePerMeter)}원/m²</div>
              </>
            ) : null}
          </>
        );
      }
    }
  };

  const renderTopInfo = (saleType: string) => {
    if (saleType === '경매' || saleType === '공매') {
      return (
        <>
          <AuctionPriceBox>
            <AuctionContentBox>
              <div>감정가({data.data.saleDate})</div>
              <AuctionContentPrice {...PriceColor('appraisedPrice')}>
                {formatToKoreanCurrency(Number(data.data.appraisedPrice))}
              </AuctionContentPrice>
            </AuctionContentBox>
            <ContentDivider
              height={DividerHeight('auction').height}
            ></ContentDivider>
            <AuctionContentBox>
              <div>
                최저입찰가(
                {(
                  (Number(data.data.lowestPrice) /
                    Number(data.data.appraisedPrice)) *
                  100
                ).toFixed(1)}
                %)
              </div>
              <AuctionContentPrice {...PriceColor('minPrice')}>
                {formatToKoreanCurrency(data.data.lowestPrice)}
              </AuctionContentPrice>
            </AuctionContentBox>
          </AuctionPriceBox>
          <SaleDateBox>
            <SaleDateInner>
              <SaleDateContent {...SaleDateFont('')}>입찰일</SaleDateContent>
              <SaleDateContent {...SaleDateFont('saleDate')}>
                {data.data.saleDate}
              </SaleDateContent>
            </SaleDateInner>
            <SaleDateContent {...SaleDateFont('D_Day')}>
              마감
              {Number(data.data.restBidDays) > 0
                ? ` D-${data.data.restBidDays}`
                : ''}
            </SaleDateContent>
          </SaleDateBox>
        </>
      );
    } else {
      return null;
    }
  };

  const splitAddress = (etcAddress: string) => {
    if (typeof etcAddress !== 'string') {
      throw new Error('Invalid input: address must be a string');

      return [];
    }
    const addressArray = etcAddress.split(',');
    return addressArray.slice(1); // 첫 번째 값을 제외하고 반환
  };

  return (
    <SummaryContainer>
      <SummaryInnerContainer>
        <SummaryTitle
          saleType={data.data.saleType}
          type={data.data.type}
          caseNo={data.data.caseNo || ''}
          court={data.data.court || ''}
          price={data.data.price || 0}
          dealdate={data.data.dealdate || ''}
          sale_idx={data.data.saleIdx || '12345'}
          is_multy={data.data.is_multy || ''}
        ></SummaryTitle>
        {renderTopInfo(data.data.saleType)}
        <SummaryInfoBox saleType={data.data.saleType}>
          {data.data.saleType && data.data.saleType === '인터넷 매물' && (
            <PriceInfo>{formatToKoreanCurrency(data.data.price)}</PriceInfo>
          )}
          {data.data.saleType === '매물' && (
            <PriceInfo>
              매매 {formatToKoreanCurrency(data.data.price)}
            </PriceInfo>
          )}
          {data.data.saleType === '지자체 물건' && (
            <PriceInfo>
              매매 {formatToKoreanCurrency(data.data.price)}
            </PriceInfo>
          )}
          <AddressBox>
            <div>{data.data.address}</div>
            {data.data.saleType === '매물' &&
              data.data.cnt &&
              (data.data.cnt ?? 0) > 1 && (
                <CntBox>
                  외 {data.data.cnt - 1}필지
                  <img
                    onClick={() => {
                      setEtcAddressModal(true);
                    }}
                    src="/asset/images/icon/green_plus.svg"
                    alt=""
                  />
                </CntBox>
              )}
          </AddressBox>
          {data.data.saleType === '매물' && data.data.desc_title !== '' && (
            <DescriptionBox>{data.data.desc_title}</DescriptionBox>
          )}
          <SummaryContentBox type={data.data.type}>
            {data.data.type === 'H' && data.data.houseArea ? (
              <>
                {/* <SummaryContentInnerBox>
                  <SummaryContent>
                    <div>토지</div>
                    {renderPrice(unit, 'land')}
                  </SummaryContent>
                  <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
                </SummaryContentInnerBox> */}
                <SummaryContentInnerBox>
                  <SummaryContent>
                    <div>건물</div>
                    {renderPrice(unit, 'house')}
                  </SummaryContent>
                  <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
                </SummaryContentInnerBox>
              </>
            ) : (
              <>
                <SummaryContent>
                  <div>{data.data.type === 'H' ? '주택' : '토지'}</div>
                  {renderPrice(unit, data.data.type === 'H' ? 'house' : 'land')}
                </SummaryContent>
                <UnitSwitch unit={unit} setUnit={setUnit}></UnitSwitch>
              </>
            )}
          </SummaryContentBox>
        </SummaryInfoBox>
      </SummaryInnerContainer>
      <SummaryCategory
        unit={unit}
        type={data.data.type}
        saleType={data.data.saleType}
        category={data.data.category}
        pyeong={data.data.pyeong}
        area={data.data.area}
        zoningBadge={data.data.zoningBadge}
        bidCount={data.data.bidCount || 0}
        approval={Number(data.approvalYear)}
        option={'N'}
        road={data.roadSurface || ''}
      ></SummaryCategory>
      <InquireBox>
        <InquireTextBox>
          <InquireTextTitle>전국 토지·주택 매매 문의</InquireTextTitle>
          <InquireText>
            <img src="/asset/images/icon/phone-red.svg" />
            02)555-2822 <span>(평일 10:00~19:00)</span>
          </InquireText>
        </InquireTextBox>
        <InquireButton
          onClick={() => {
            navigate('/inquiry');
          }}
        >
          온라인 문의
        </InquireButton>
      </InquireBox>
      {data.data.saleType === '인터넷 매물' ? (
        <NoteInfoBox>
          <div>※</div>
          <div>
            본 정보는 참고용으로만 제공되며, 해당 정보에 대한 직/간접적인 중개
            서비스를 제공하지 않습니다.
          </div>
        </NoteInfoBox>
      ) : null}
      {etcAddressModal && (
        <EtcAddressModalContainer
          onClick={() => {
            setEtcAddressModal(false);
          }}
        >
          <Inner
            onClick={(e) => {
              e.stopPropagation(); // 이벤트 전파 방지
            }}
          >
            <TitleBox>
              <TitleText>함께 매도하는 필지</TitleText>
              <img
                src="/asset/images/close/close-gray-circle.svg"
                alt=""
                onClick={() => {
                  setEtcAddressModal(false);
                }}
                style={{ cursor: 'pointer' }}
              />
            </TitleBox>
            <Divider>
              <div></div>
            </Divider>
            <>
              {splitAddress(data.data.etcAddress ?? '').map((address, idx) => (
                <EtcAddressBox key={idx}>{address}</EtcAddressBox>
              ))}
            </>
            <NoticeWrapper>
              <NoticeBox>
                <div>※</div>
                <div>분석은 대표 주소지만 제공합니다</div>
              </NoticeBox>
            </NoticeWrapper>
          </Inner>
        </EtcAddressModalContainer>
      )}
    </SummaryContainer>
  );
};
export default Summary;

interface typeProps {
  type: string;
}

interface saleTypeProps {
  saleType: string;
}

const DescriptionBox = styled.div`
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const NoticeBox = styled.div`
  padding: 10px 16px;
  background: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const NoticeWrapper = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

const EtcAddressBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  color: #616161;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;

const Divider = styled.div`
  width: 100%;
  padding: 0px 16px;
  div {
    height: 1px;
    background-color: #e5e5e5;
    color: #616161;
  }
`;

const TitleText = styled.span`
  flex: 1; /* 남은 공간을 차지하게 함 */
  display: flex; /* 내부 요소들을 flexbox로 정렬 */
  box-sizing: border-box;
  overflow: hidden;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
`;

const TitleBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
`;

const Inner = styled.div`
  width: 328px;
  // height: 461px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 8px;
  padding-bottom: 16px;
`;

const EtcAddressModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  max-width: 720px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.52);
`;

const CntBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const AddressBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SummaryContentInnerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const InquireButton = styled.div`
  padding: 8px 24px;
  border-radius: 999px;
  background-color: #09ce7d;
  color: #171e26;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  cursor: pointer;
`;

const InquireText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const InquireTextTitle = styled.div`
  color: #171e26;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
`;

const InquireTextBox = styled.div`
  display: flex;
  flex-direction: column;
  color: #171e26;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  gap: 2px;
  span {
    color: rgba(23, 30, 38, 0.56);
  }
  img {
    width: 20px;
    height: 20px;
  }
`;

const InquireBox = styled.div`
  background-color: #eaf8f2;
  width: 100%;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SummaryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  gap: 24px;
  justify-content: space-between;
  background-color: #ffffff;
`;

const SummaryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 16px;
`;

const AuctionPriceBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 83px;
  padding: 12px 16px;
  background-color: #fafafa;
  border-radius: 8px;
  gap: 8px;
`;

const AuctionContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  flex: 1;
`;
const ContentDivider = styled.div<DividerProps>`
  background-color: rgba(112, 115, 124, 0.16);
  width: 1px;
  height: ${(props) => props.height}px;
`;

const AuctionContentPrice = styled.div<PriceColorProps>`
  color: ${(props) => props.priceColor};
  font-size: 20px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 29px;
  word-wrap: break-word;
`;

const SaleDateBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #eaf8f2;
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  border-radius: 8px;
`;

const SaleDateInner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SaleDateContent = styled.div<SaleDateProps>`
  color: ${(props) => props.fontColor};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: ${(props) => props.fontWeight};
  line-height: 20px;
  word-wrap: break-word;
`;

const SummaryInfoBox = styled.div<saleTypeProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.saleType === '매물' ? '16px' : '4px')};
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const SummaryContentBox = styled.div<typeProps>`
  display: flex;
  // flex-direction: ${(props) => (props.type === 'H' ? 'column' : 'row')};
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #fafafa;
  border-radius: 8px;
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const SummaryCategoryInfoBox = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  // background-color: #f5f5f5;
`;

const NoteInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 10px 16px;
  border-radius: 8px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  background-color: rgba(112, 115, 124, 0.05);
`;

const PriceInfo = styled.div`
  color: black;
  font-size: 24px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 34.8px;
  word-wrap: break-word;
`;
