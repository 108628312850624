import styled from 'styled-components';
import { S } from './brokerModal.style';
const BrokerModal = ({
  is_broker,
  brokerPropertyYN,
  setBrokerModalOn,
  brokerData,
}: any) => {
  const TitleText = (brokerPropertyYN: boolean, is_broker: string) => {
    if (brokerPropertyYN) {
      return '부동산 안심 중개 파트너';
    } else if (is_broker === 'Y') {
      return '부동산 중개';
    } else {
      return '매수 문의하기';
    }
  };

  const renderBrokerInfo = (brokerData: any) => {
    if (is_broker === 'Y') {
      return (
        <S.BrokerInfoInner>
          <S.BrokerInfo>
            <img
              src={
                brokerData.img && brokerData.img !== ''
                  ? brokerData.img
                  : './asset/images/icon/broker_default_img.png'
              }
            ></img>
            <S.BrokerCompany>
              <S.CompanyName>{brokerData.companyName}</S.CompanyName>
              <div>
                대표 <span>{brokerData.brokerName}</span>
              </div>
            </S.BrokerCompany>
          </S.BrokerInfo>
          <S.Divider></S.Divider>
          <S.BrokerCall>
            <div>
              <img
                src="./asset/images/icon/green_call.svg"
                style={{ width: '24px', height: '24px' }}
                alt=""
              />
            </div>
            <div>{brokerData.brokerNumber}</div>
          </S.BrokerCall>
        </S.BrokerInfoInner>
      );
    } else {
      return (
        <S.BrokerInfoInner>
          <S.BrokerCall>
            <div>
              <img
                src="./asset/images/icon/green_call.svg"
                style={{ width: '24px', height: '24px' }}
                alt=""
              />
            </div>
            <div>{brokerData.brokerNumber}</div>
          </S.BrokerCall>
        </S.BrokerInfoInner>
      );
    }
  };

  return (
    <S.Container
      onClick={() => {
        setBrokerModalOn(false);
      }}
    >
      <S.Inner
        onClick={(e) => {
          e.stopPropagation(); // 이벤트 전파 방지
        }}
      >
        <S.PageTop>
          <img
            style={{ width: '24px', height: '24px' }}
            src="/asset/images/close/close-darkgray.svg"
            alt=""
            onClick={() => {
              setBrokerModalOn(false);
            }}
          />
        </S.PageTop>
        <S.TextWrapper>
          <S.DescriptionWrapper>
            <S.TitleBox>
              <S.Title>{TitleText(brokerPropertyYN, is_broker)}</S.Title>
              {brokerPropertyYN && (
                <S.Title>
                  <span>{brokerData.region}</span>
                </S.Title>
              )}
            </S.TitleBox>
            {renderBrokerInfo(brokerData)}
            <S.NoticeInfoBox>
              <div>트랜스파머에서 보고 연락했다고 말씀해주세요!</div>
              {brokerPropertyYN && (
                <div>
                  <div>
                    <span>
                      트랜스파머와 함께하는 지역 전문 파트너 공인중개사가
                    </span>
                  </div>
                  <div>
                    <span>안심거래를 도와드립니다.</span>
                  </div>
                </div>
              )}
            </S.NoticeInfoBox>
          </S.DescriptionWrapper>
          <S.Button
            onClick={() => {
              setBrokerModalOn(false);
            }}
          >
            확인
          </S.Button>
        </S.TextWrapper>
      </S.Inner>
    </S.Container>
  );
};

export default BrokerModal;
