import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface CompletionModalProps {
  setOpenCompletionModal: (value: boolean) => void;
}

const CompletionModal = ({ setOpenCompletionModal }: CompletionModalProps) => {
  const navigate = useNavigate();

  return (
    <ModalContainer>
      <ModalInner>
        <TopClose>
          <img
            src="/asset/images/close/close24.svg"
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenCompletionModal(false);
              navigate('/mychon?tab=saleItem');
            }}
          />
        </TopClose>
        <ContentBox>
          <img
            src="/asset/images/check/Check24GreenCircle2.svg"
            alt=""
            style={{ width: '48px' }}
          />
          <TextBox>
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: 16,
                color: '#000',
              }}
            >
              매물 수정 완료
            </div>
            <div>
              수정 내역은{' '}
              <span style={{ color: '#1E9B6B' }}>마이촌 {`>`} 매물관리</span>
              에서 확인 가능해요
            </div>
          </TextBox>
          <ButtonBox>
            <Button
              style={{
                color: '#1E9B6B',
                border: '1px solid rgba(112, 115, 124, 0.22)',
              }}
              onClick={() => {
                navigate('/');
              }}
            >
              홈으로
            </Button>
            <Button
              style={{ color: 'white', backgroundColor: '#1E9B6B' }}
              onClick={() => {
                setOpenCompletionModal(false);
                navigate('/mychon?tab=saleItem');
              }}
            >
              확인
            </Button>
          </ButtonBox>
        </ContentBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default CompletionModal;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: inherit;
  z-index: 100;
`;

const ModalInner = styled.div`
  width: 312px;
  padding: 24px 16px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

const TopClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
`;
const Button = styled.span`
  flex: 1;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;
