import {
  Container,
  PageTop,
  ContentBox,
  Text,
  ImgageBox,
  ImgageItem,
  GifBox,
  ImgTextBox,
} from './Brokerage.styles';
import BottomNavigation from '../components/navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import BottomButtonBox from '../components/bottomButton/BottomButtonBox';
import { isLogin } from '../../utilities/auth';

const Brokerage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <PageTop>
        <span>매물등록</span>
        <img src="/asset/images/logo/logo_normal.svg" alt="" />
      </PageTop>
      <ContentBox>
        <Text>
          무료로 <br />
          <span style={{ color: 'rgba(30, 155, 107, 1)' }}>매물등록 </span>
          해드려요!
        </Text>
        <ImgageBox>
          <ImgageItem>
            <GifBox>
              <img
                src="/asset/images/gif/brokarage-01.gif"
                alt=""
                style={{
                  height: '198px',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                }}
              />
            </GifBox>
            <ImgTextBox>
              <img src="/asset/images/icon/map-color.svg" alt="" />
              <span>매물 등록 후 나의 매물이 지도에 등록돼요</span>
            </ImgTextBox>
          </ImgageItem>
          <ImgageItem>
            <GifBox>
              <img
                src="/asset/images/gif/brokarage-02.gif"
                alt=""
                style={{
                  height: '273px',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                }}
              />
            </GifBox>
            <ImgTextBox>
              <img src="/asset/images/icon/photo.svg" alt="" />
              <span>상세페이지에서 매물 정보를 확인할 수 있어요</span>
            </ImgTextBox>
          </ImgageItem>
        </ImgageBox>
        <BottomButtonBox
          text="무료로 매물 등록하기"
          onClick={() => {
            isLogin() ? navigate('/register/property') : navigate('/login');
          }}
          bottom={54}
        />
      </ContentBox>
      <BottomNavigation />
    </Container>
  );
};

export default Brokerage;
