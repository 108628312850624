import { useState } from 'react';
import { TodayDate } from '../../../../function/function';
import {
  DescriptionContainer,
  SectionContainer,
  TitleContainer,
  Title,
  Left,
  DateBox,
  TopText,
  TopTextStrong,
  BottomText,
  BottomTextStrong,
  Right,
  Circle,
  ProgressContainer,
  GrayBar,
  GreenBar,
  PercentageBox,
  TooltipBox,
  TooltipText,
  PercentageItem,
  NoServeData,
} from './SalesPriceComparison.styles';

const SalesPriceComparison = ({
  comparisonOfSelling,
  is_multy,
}: {
  comparisonOfSelling: number;
  is_multy: string;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const calculateLeftPosition = (percentage: number) => {
    const min = -50;
    const max = 50;

    const limitedPercentage = Math.max(min, Math.min(max, percentage));

    const relativePercentage = ((limitedPercentage - min) / (max - min)) * 100;
    return `${relativePercentage}%`;
  };

  const RenderText = (percentage: number) => {
    if (percentage > 20) {
      return (
        <Left>
          <TopText>
            이 매물은 현재 주변{' '}
            <TopTextStrong>매물 평당 평균 시세</TopTextStrong> 보다
          </TopText>
          <BottomText>
            <span>약</span>{' '}
            <BottomTextStrong>{percentage.toLocaleString()}%</BottomTextStrong>
            <span>높아요</span>
          </BottomText>
        </Left>
      );
    }
    if (percentage < -20) {
      return (
        <Left>
          <TopText>
            이 매물은 현재 주변{' '}
            <TopTextStrong>매물 평당 평균 시세</TopTextStrong> 보다
          </TopText>
          <BottomText>
            <span>약</span>{' '}
            <BottomTextStrong style={{ color: '#358CFF' }}>
              {percentage.toLocaleString()}%
            </BottomTextStrong>
            <span>낮아요</span>
          </BottomText>
        </Left>
      );
    } else {
      return (
        <Left>
          <TopText>
            이 매물은 현재 주변{' '}
            <TopTextStrong>매물 평당 평균 시세</TopTextStrong>와
          </TopText>
          <BottomText>
            <BottomTextStrong style={{ color: '#1E9B6B' }}>
              비슷해요
            </BottomTextStrong>
          </BottomText>
        </Left>
      );
    }
  };

  const RenderDotImage = (percentage: number) => {
    if (percentage > 20) {
      return '/asset/images/high-dot.svg';
    }
    if (percentage < -20) {
      return '/asset/images/low-dot.svg';
    } else {
      return '/asset/images/near-dot.svg';
    }
  };

  const ChangeTransForm = (percentage: number) => {
    if (percentage > 30) {
      return 'translateX(-80%)';
    } else if (percentage >= -30 && percentage <= 30) {
      return 'translateX(-50%)';
    } else {
      return 'translateX(-20%)';
    }
  };

  return (
    <SectionContainer>
      <TitleContainer>
        <Title>
          <span>매매가 비교</span>
          <img
            src="/asset/images/icon/information-outline.svg"
            alt=""
            onClick={() => {
              setShowTooltip(true);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Title>
        <DateBox>분석 일자 {TodayDate()}</DateBox>
      </TitleContainer>
      {is_multy === '복합' ? (
        <DescriptionContainer>
          <NoServeData>
            복합필지의 경우 매매가 비교를 제공하지 않습니다.
          </NoServeData>
        </DescriptionContainer>
      ) : (
        <>
          <DescriptionContainer>
            {RenderText(comparisonOfSelling)}
          </DescriptionContainer>
          <ProgressContainer>
            <GrayBar>
              <GreenBar></GreenBar>
              <img
                src={RenderDotImage(comparisonOfSelling)}
                alt=""
                style={{
                  position: 'absolute',
                  bottom: '-6px',
                  left: calculateLeftPosition(comparisonOfSelling),
                  zIndex: 10,
                  transform: ChangeTransForm(comparisonOfSelling),
                }}
              />
            </GrayBar>
            <PercentageBox>
              <PercentageItem position={30}>-20%</PercentageItem>
              <PercentageItem position={50}>평당 평균 시세</PercentageItem>
              <PercentageItem position={70}>+20%</PercentageItem>
            </PercentageBox>
          </ProgressContainer>
        </>
      )}
      {showTooltip && (
        <TooltipBox>
          <TooltipText>
            해당 주소지 매물의 매매가와 주변 이내 유사 매물들의 평균 매매가를
            비교하여, 해당 매물이 시세 구간 중 어디에 위치하는지 시각적으로
            보여줍니다.
          </TooltipText>
          <img
            src="/asset/images/close/close.svg"
            alt=""
            onClick={() => {
              setShowTooltip(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </TooltipBox>
      )}
    </SectionContainer>
  );
};

export default SalesPriceComparison;
