import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/swiper-bundle.css';
import { useRef, useState } from 'react';
import { ImgUrlConvert } from '../../../../function/function';

interface ImageSliderProps {
  imageData: string[];
  saleType?: string;
  desc_detail?: string;
}

const ImageSlider = ({
  imageData,
  saleType,
  desc_detail,
}: ImageSliderProps) => {
  const swiperRef = useRef<SwiperCore>();
  const [currentSlide, setCurrentSlide] = useState(0);

  

  return (
    <DetailImgContainer>
      <div>매물사진</div>
      {imageData?.length > 0 ? (
        <>
          <ImgBox>
            <Swiper
              slidesPerView={1}
              loop
              onSwiper={(swiper: SwiperCore) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiper: SwiperCore) =>
                setCurrentSlide(swiper.realIndex)
              }
            >
              {imageData.map((image, idx) => (
                <SwiperSlide key={idx}>
                  <img
                    src={ImgUrlConvert(image)}
                    alt=""
                    style={{
                      width: '100%',
                      height: '247px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <ImgNumber>{`${currentSlide + 1} / ${imageData.length}`}</ImgNumber>
            <ArrowLeft
              src="/asset/images/arrow/slider-arrow-left.svg"
              alt=""
              onClick={() => swiperRef.current?.slidePrev()}
            />
            <ArrowRight
              src="/asset/images/arrow/slider-arrow-right.svg"
              alt=""
              onClick={() => swiperRef.current?.slideNext()}
            />
          </ImgBox>
          <DetailBox>
            <DetailContent>
              <img src="/asset/images/icon/chat.svg"></img>
              <div>
                <span>매물 관련 설명</span>
              </div>
            </DetailContent>
            <DetailContent>{desc_detail}</DetailContent>
          </DetailBox>
        </>
      ) : (
        <ImgContent>등록된 이미지가 없습니다.</ImgContent>
      )}
    </DetailImgContainer>
  );
};

export default ImageSlider;

const DetailContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  span {
    color: #616161;
    font-size: 14px;
    font-family: Pretendard-medium;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
  }
`;

const DetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  background: #f5f5f5;
  border-radius: 8px;
`;

const DetailImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Bold;
  line-height: 24px;
  word-wrap: break-word;
  background-color: #ffffff;
`;

const ImgContent = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-Medium;
  line-height: 24px;
  word-wrap: break-word;
`;

const ImgBox = styled.div`
  width: 100%;
  height: 247px;
  position: relative;
`;

const ImgNumber = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  height: 18px;
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.52);
  color: white;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
const ArrowLeft = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
`;
const ArrowRight = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
`;
