import styled from 'styled-components';
import colors from '../../color';

interface NoticeModalProps {
  setShowNoticeModal: (value: boolean) => void;
}

const NoticeModal = ({ setShowNoticeModal }: NoticeModalProps) => {
  return (
    <ModalContainer>
      <ModalInner>
        <TopClose>
          <img
            src="/asset/images/close/close24.svg"
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowNoticeModal(false);
            }}
          />
        </TopClose>
        <ContentBox>
          <ContentTitle>
            중개대상물의 표시·광고 <br /> 명시사항 세부기준 준수 안내
          </ContentTitle>
          <div>
            매물 등록 시 실제 매물에 맞는 정보를 <br /> 입력해주세요. <br />
            최초 등록 후, 매물 정보가 변경되었을 때는 <br />
            등록 정보를 수정하시길 바랍니다.
          </div>
          <div>
            허위매물 및 표시·광고 명시사항 <br /> 세부 기준 위반으로 신고되는
            경우, <br /> 모든 책임은 매물 등록인에게 있습니다.
          </div>
        </ContentBox>
        <ConfirmButton
          onClick={() => {
            setShowNoticeModal(false);
          }}
        >
          확인
        </ConfirmButton>
      </ModalInner>
    </ModalContainer>
  );
};

export default NoticeModal;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: inherit;
  z-index: 100;
`;
const ModalInner = styled.div`
  width: 312px;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;
const TopClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.labelAlternative};
  text-align: center;
`;
const ContentTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: Pretendard-Bold;
  color: #000;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(112, 115, 124, 0.16);
`;

const ConfirmButton = styled.div`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(30, 155, 107, 1);
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  cursor: pointer;
`;
