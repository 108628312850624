import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class TermsService {
  static version = async (type: string) => {
    const result = await request.get(`terms/version?type=${type}`);
    return result.data;
  };
  static terms = async (version: number, type: string) => {
    const result = await request.get(`terms/${version}?type=${type}`);
    return result.data;
  };
}
