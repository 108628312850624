import styled from 'styled-components';
import SelectTypeBox from './SelectTypeBox';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sales from '../../../service/Sales';
import SaleCompleteModal from './SaleCompleteModal';
import { transNum } from '../../../function/function';

const SaleItemList = ({
  itemList,
  setShowModal,
  setDeleteIdx,
  setDeleteModalOn,
  changeYN,
  setChangeYN,
  hasMore,
  handleLoadMore,
}: any) => {
  // 각 아이템의 상태를 관리하는 상태 훅
  const [itemStatuses, setItemStatuses] = useState<Record<number, string>>({});
  const navigate = useNavigate();
  const [alertYN, setAlertYN] = useState(false);

  const [itemId, setItemId] = useState(0);
  const [itemStatus, setitemStatus] = useState('');
  const [itemStatusChange, setItemStatusChange] = useState(true);

  // itemList가 변경될 때 itemStatuses를 초기화
  useEffect(() => {
    const initialStatuses = itemList.reduce(
      (acc: any, item: any) => {
        acc[item.sale_idx] = item.state; // item.id를 키로 초기화
        return acc;
      },
      {} as Record<number, string>,
    );
    setItemStatuses(initialStatuses);
  }, [itemList]);

  useEffect(() => {
    if (alertYN) return; // 거래완료 모달이 켜져있으면 return
    if (!itemStatusChange) return; // 상태를 변경하지 않는다고 하면 return
    if (itemId === 0) return; // 아이템 아이디가 없으면 return
    ChangeStatus(itemId, itemStatus);
  }, [itemId, alertYN, itemStatus, itemStatusChange]);

  // 상태를 변경하는 함수
  const ChangeStatus = async (id: number, newStatus: string) => {
    const updatedStatuses = {
      ...itemStatuses,
      [id]: newStatus,
    };

    setItemStatuses(updatedStatuses);

    const res = await Sales.stateChange(String(id), newStatus);
    if (res !== 200) {
      console.error('Error changing status:', res);
      return;
    }

    const statusText = (newStatus: string): string => {
      switch (newStatus) {
        case 'Y':
          return '공개로 변경되었습니다';
        case 'N':
          return '비공개로 변경되었습니다';
        case 'T':
          return '거래완료로 변경되었습니다';
        default:
          return '공개로 변경되었습니다';
      }
    };
    setShowModal({ status: true, text: statusText(newStatus) });
    setChangeYN(!changeYN); // 상태 변경 시 리렌더링을 위해 상태 변경
  };

  // 상태 변경을 받아오는 함수
  const handleStatusChange = (id: number, newStatus: string) => {
    setItemId(id);
    setitemStatus(newStatus);
    if (newStatus === 'T') {
      setAlertYN(true);
      setItemStatusChange(false);
    } else {
      setItemStatusChange(true);
    }
  };

  function formatDate(isoDate: string) {
    try {
      const [datePart, timePart] = isoDate.split('T');
      const [year, month, day] = datePart.split('-');
      const [hours, minutes, seconds] = timePart.split(':');

      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds.split('.')[0]}`;
    } catch (e) {
      const date = new Date(isoDate);
      date.setHours(date.getHours() - 9);

      // 날짜와 시간을 개별적으로 추출
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // 원하는 포맷으로 반환
      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
    }
  }

  return (
    <Container>
      {alertYN && (
        <SaleCompleteModal
          setAlertYN={setAlertYN}
          setItemStatusChange={setItemStatusChange}
        />
      )}
      {itemList.map((item: any, index: any) => {
        return (
          <>
            <ItemListWrapper key={item.sale_idx}>
              <ItemWrapper>
                <ItemList>
                  <ItemAddress
                    onClick={() => {
                      navigate(`/detail?c_idx=${item.c_idx}`);
                    }}
                  >
                    <img src="/asset/images/icon/location-black.svg" alt="" />
                    <div>
                      {item.address}{' '}
                      {item.cnt &&
                      item.cnt !== 'null' &&
                      !isNaN(Number(item.cnt)) &&
                      Number(item.cnt) > 1
                        ? `외 ${Number(item.cnt) - 1}개`
                        : ''}
                    </div>
                  </ItemAddress>
                  <div>
                    <span>{transNum(Number(item.price))}원</span>
                  </div>
                  <div>
                    <div>매물번호 {item.sale_idx}</div>
                  </div>
                </ItemList>
                <ItemList2>
                  <SortBox>
                    <SelectTypeBox
                      value={itemStatuses[item.sale_idx]}
                      onChange={(newStatus) =>
                        handleStatusChange(item.sale_idx, newStatus)
                      } // 상태 변경 함수 호출
                      type={'itemStatus'}
                    />
                  </SortBox>
                  <div>
                    <span>등록일 {formatDate(item.reg_time)}</span>
                  </div>
                </ItemList2>
              </ItemWrapper>
              <ButtonBox>
                <Button
                  onClick={() => {
                    setDeleteIdx(item.sale_idx);
                    setDeleteModalOn(true);
                  }}
                  value={''}
                >
                  삭제하기
                </Button>
                <Button
                  onClick={() => {
                    if (item.state === 'T') {
                      return;
                    } else {
                      navigate(`/edit/property/${item.sale_idx}`);
                    }
                  }}
                  value={item.state}
                >
                  수정하기
                </Button>
              </ButtonBox>
            </ItemListWrapper>
            {index === itemList.length - 1 ? null : <Divider></Divider>}
          </>
        );
      })}
      {!hasMore ? null : (
        <MoreButton
          onClick={() => {
            handleLoadMore();
          }}
        >
          더보기
        </MoreButton>
      )}
    </Container>
  );
};

export default SaleItemList;

interface ValueProps {
  value: string;
}

const MoreButton = styled.div`
  width: 100%;
  padding: 12px 16px;
  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  display: flex;
  background: rgba(112, 115, 124, 0.08);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Button = styled.div<ValueProps>`
  width: 100%;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px #e1e2e4 solid;
  color: ${(props) =>
    props.value === 'T' ? 'rgba(55, 56, 60, 0.16)' : '#616161'};
  background: ${(props) => (props.value === 'T' ? '#F7F7F8' : 'white')};
  cursor: ${(props) =>
    props.value === 'T' ? 'null' : 'pointer'}; // 거래완료 상태일 때는 클릭 불가
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ItemAddress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  span {
    color: #1e9b6b;
    font-weight: 700;
  }
`;

const SortBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 73px;
  align-self: flex-end;
`;

const ItemList2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 6px;
  span {
    color: rgba(55, 56, 60, 0.28);
    font-size: 12px;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: #616161;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ItemListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #e1e2e4;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 100%;
`;
