import { useEffect, useState } from 'react';
import TermsService from '../../service/terms';
import ScrollToTopButton from '../components/toTopBtn/ScrollToTopButton';
import './Terms.css';
import { useNavigate } from 'react-router-dom';

interface ContentItem {
  seq: number;
  title: string;
  content:
    | {
        clause_idx: number;
        seq: number;
        title: string;
        content: {
          clause_idx: number;
          seq: number;
          title: string;
          content: any;
        }[];
      }[]
    | null;
  clause_idx: number;
}

interface Term {
  seq: number;
  title: string;
  content: ContentItem[];
  terms_idx: number;
}

interface ContentItem2 {
  seq: number;
  title: string;
  content: string | null;
  clause_idx: number;
}

interface Term2 {
  seq: number;
  title: string;
  content: ContentItem2[];
  terms_idx: number;
}

const PrivacyTerms = ({ SNS, setTermsOpen }: any) => {
  const [verisionList, setVersionList] = useState<
    { version: 0; create_date: '' }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<{
    version: number;
    create_date: string;
  } | null>(null);

  const [terms, setTerms] = useState<Term[]>([]);
  const [addendum, setAddendum] = useState<Term2[]>([]);

  const navigate = useNavigate();

  const getDateList = async () => {
    try {
      const res = await TermsService.version('P');
      setVersionList(res.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDateList();
  }, []);

  const getTerms = async (version: number) => {
    try {
      const res = await TermsService.terms(version, 'P');
      setAddendum(res.result.addendum);
      setTerms(res.result.list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      getTerms(selectedDate.version);
    } else if (verisionList.length > 0) {
      getTerms(verisionList[0].version);
    }
  }, [selectedDate, verisionList]);

  const dummy = [
    { name: '1. 개인정보처리방침의 목적', href: '#tag1' },
    { name: '2. 개인정보 수집 항목', href: '#tag2' },
    { name: '3. 개인정보의 처리 및 보유 기간 ', href: '#tag3' },
    { name: '4. 개인정보의 제3자 제공에 관한 사항', href: '#tag4' },
    { name: '5. 개인정보의 파기 절차 및 방법에 관한 사항', href: '#tag5' },
    { name: '6. 미이용자의 개인정보 파기 등에 관한 조치', href: '#tag6' },
    { name: '7. 이용자와 법정대리인의 권리·의무 및 행사방법 ', href: '#tag7' },
    { name: '8. 개인정보의 안전성 확보조치에 관한 사항', href: '#tag8' },
    {
      name: '9. 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항',
      href: '#tag9',
    },
    { name: '10. 가명정보 처리에 관한 사항  ', href: '#tag10' },
    { name: '11. 개인정보 보호책임자와 민원처리 부서', href: '#tag11' },
    { name: '12. 이용자의 권익침해에 대한 구제방법', href: '#tag12' },
  ];

  const renderTitle = (seq: number) => {
    switch (seq) {
      case 1:
        return '개인정보처리방침의 목적';
      case 2:
        return '개인정보 수집 항목';
      case 3:
        return '개인정보의 처리 및 보유 기간';
      case 4:
        return '개인정보의 제3자 제공에 관한 사항';
      case 5:
        return '개인정보의 파기 절차 및 방법에 관한 사항';
      case 6:
        return '미이용자의 개인정보 파기 등에 관한 조치';
      case 7:
        return '이용자와 법정대리인의 권리·의무 및 행사방법';
      case 8:
        return '개인정보의 안전성 확보조치에 관한 사항';
      case 9:
        return '개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항';
      case 10:
        return '가명정보 처리에 관한 사항';
      case 11:
        return '개인정보 보호책임자와 민원처리 부서';
      case 12:
        return '이용자의 권익침해에 대한 구제방법';
      default:
        return '';
    }
  };

  return (
    <div className="wrap" style={{ height: SNS && '100%' }}>
      <ScrollToTopButton />
      <div className="page-top">
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>개인정보처리방침</p>
      </div>
      <div className="terms-inner">
        <div className="terms-title">
          <span>개인정보처리방침</span>
          <div className="version-select">
            <select
              className="select-non-border"
              name=""
              id=""
              onChange={(e) => {
                const selectedVersion = verisionList.find(
                  (v) => v.version === Number(e.target.value),
                );
                if (selectedVersion) {
                  setSelectedDate(selectedVersion);
                }
              }}
              value={selectedDate?.version || ''}
            >
              {verisionList.map((item) => (
                <option key={item.version} value={item.version}>
                  {item.create_date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="terms-content-box">
          <div className="terms-privacy-script">
            트랜스파머(주)(이하 ‘트랜스파머’)는 이용자의 자유와 권리 보호를 위해
            「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게
            개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보
            보호법」 제30조에 따라 이용자에게 개인정보 처리에 관한 절차 및
            기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수
            있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.{' '}
          </div>
          <div className="terms-anchor-list">
            {dummy.map((item: any, idx: number) => {
              return (
                <a
                  key={idx}
                  className="terms-content-item-text pointer"
                  href={item.href}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
          {terms.map((term) => (
            <div
              key={term.terms_idx}
              style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
            >
              {term.content.map((item) => (
                <div key={item.clause_idx} className="terms-content-item">
                  <div
                    id={`tag${item.seq}`}
                    className="terms-content-item-title"
                  >
                    {item.seq !== 13 ? `${item.seq}.` : ''}{' '}
                    {renderTitle(item.seq)}
                  </div>
                  <div
                    className="terms-content-item-text"
                    dangerouslySetInnerHTML={{
                      __html: item.title.replace(/\n/g, '<br />'),
                    }}
                  ></div>
                  {item.content &&
                    item.content.map((content) => (
                      <div
                        key={content.clause_idx} // Make sure to use unique keys
                        style={{ fontSize: '13px', marginLeft: '4px' }}
                      >
                        {content.seq}
                        {')'} {content.title}
                        {content.content &&
                          content.content.map((c, idx) => (
                            <div key={idx}>- {c.title}</div>
                          ))}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyTerms;
