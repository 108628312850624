import Request from '../@api/request';

const baseURL = `${process.env.REACT_APP_HOST || 'http://localhost:3330'}`;

export const request = new Request(baseURL);

export default class Sales {
  static list = async (
    swLat: number,
    neLat: number,
    swLng: number,
    neLng: number,
    type: string,
    category: string,
    zoning: string,
    minPrice: string,
    maxPrice: string,
    minArea: string,
    maxArea: string,
    minYear: string,
    maxYear: string,
    saleType: string,
    pnu: string,
    sort: string,
    zoom: string,
  ) => {
    const result = await request.get(
      `sales?swLat=${swLat}&neLat=${neLat}&swLng=${swLng}&neLng=${neLng}&type=${type}&category=${category}&zoning=${zoning}&minPrice=${minPrice}&maxPrice=${maxPrice}&minArea=${minArea}&maxArea=${maxArea}&minYear=${minYear}&maxYear=${maxYear}&saleType=${saleType}&pnu=${pnu}&sort=${sort}&zoom=${zoom}`,
    );
    return result.data;
  };
  static region = async (code: string) => {
    const result = await request.get(`sales/region?code=${code}`);
    return result.data;
  };
  static nonSaleList = async (lat: string, lng: string) => {
    const result = await request.get(`sales/non-sale?lat=${lat}&lng=${lng}`);
    return result.data;
  };
  static detail = async (c_idx: string) => {
    const result = await request.get(`sales/${c_idx}`);
    return result.data;
  };

  static origin = async () => {
    const result = await request.get(`sales/origin/list`);
    return result.data;
  };
  static nonSaleInfo = async (type: string, pnu: string, housekey: string) => {
    const result = await request.get(
      `sales/non-sale/info?type=${type}&pnu=${pnu}&housekey=${housekey}`,
    );
    return result.data;
  };
  static listAll = async (
    type: string,
    category: string,
    zoning: string,
    minPrice: string,
    maxPrice: string,
    minArea: string,
    maxArea: string,
    minYear: string,
    maxYear: string,
    saleType: string,
    pnu: string,
    sort: string,
    c_idx: string,
    price: string,
    area: string,
    regTime: string,
    isSafe: string,
    r_c_idx: string,
    r_price: string,
    r_area: string,
    r_regTime: string,
  ) => {
    const minPyeong = AreaToPyeong(minArea);
    const maxPyeong = AreaToPyeong(maxArea);
    const result = await request.get(
      `sales/list/all?type=${type}&category=${category}&zoning=${zoning}&minPrice=${minPrice}&maxPrice=${maxPrice}&minArea=${minPyeong}&maxArea=${maxPyeong}&minYear=${minYear}&maxYear=${maxYear}&saleType=${saleType}&pnu=${pnu}&sort=${sort}&c_idx=${c_idx}&price=${price}&area=${area}&regTime=${regTime}&isSafe=${isSafe}&r_c_idx=${r_c_idx}&r_price=${r_price}&r_area=${r_area}&r_regTime=${r_regTime}`,
    );
    return result.data;
  };
  static center = async (code: string, dataType: string[]) => {
    let type = dataType.join(',');
    const result = await request.get(`sales/sales/center/${code}/${type}`);
    return result.data;
  };
  static registerSaleItem = async (data: any) => {
    const res = await request.post(`sales/register`, data);
    return res;
  };
  static changeArea = async (data: any) => {
    const res = await request.post(`sales/address/area`, data);
    return res;
  };

  static mySaleItemList = async (
    sale_idx: number,
    sort: string,
    pnu: string,
    type: string,
    state: string,
  ) => {
    let api = `sales/my/list?sort=${sort}&type=${type}&state=${state}`;
    // pnu가 있으면 api에 추가, sale_idx가 0이 아니면 lastIdx에 sale_idx 추가
    if (pnu) {
      api += `&code=${pnu}`;
    }
    if (sale_idx !== 0) {
      api += `&lastIdx=${sale_idx}`;
    }

    const res = await request.get(api);
    return res.data;
  };
  static salesItemDetail = async (sale_idx: string) => {
    const res = await request.get(`sales/my/detail/${sale_idx}`);
    return res.data;
  };
  static salesItemUpdate = async (data: any) => {
    const res = await request.patch(`sales/update`, data);
    return res.data;
  };

  static stateChange = async (sale_idx: string, state: string) => {
    const data = {
      sale_idx: sale_idx,
      state: state,
    };
    const res = await request.patch(`sales/state`, { ...data });
    return res.status;
  };

  static salesItemDelete = async (sale_idx: string, delete_reason: string) => {
    const data = {
      sale_idx: sale_idx,
      delete_reason: delete_reason,
    };
    const res = await request.patch(`sales/delete`, { ...data });
    return res.data;
  };

  static brokerList = async (pnu: string) => {
    const result = await request.get(`sales/broker/list?code=${pnu}`);
    return result.data;
  };
}
const AreaToPyeong = (Area: string) => {
  return Math.floor(Number(Area) * 3.30579);
};
