import React, { createContext, useContext, useState } from 'react';

interface AddressListProps {
  postCode: string;
  jibunAddress: string;
  detail: string;
}

interface SaleItemContextTypes {
  registrant: string;
  setRegistrant: React.Dispatch<React.SetStateAction<string>>;
  isHolder: string;
  setIsHolder: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  addressList: AddressListProps[];
  setAddressList: React.Dispatch<React.SetStateAction<AddressListProps[]>>;
  isAddressPublic: string;
  setIsAddressPublic: React.Dispatch<React.SetStateAction<string>>;
  saleItemImages: File[];
  setSaleItemImages: React.Dispatch<React.SetStateAction<File[]>>;
  imgList: string[];
  setImgList: React.Dispatch<React.SetStateAction<string[]>>;
  saleItemImagesURLs: string[];
  setSaleItemImagesURLs: React.Dispatch<React.SetStateAction<string[]>>;
  explanation: string;
  setExplanation: React.Dispatch<React.SetStateAction<string>>;
  detailExplanation: string;
  setDetailExplanation: React.Dispatch<React.SetStateAction<string>>;
  area: string;
  setArea: React.Dispatch<React.SetStateAction<string>>;
  expectedAmount: string;
  setExpectedAmount: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  isView: boolean;
  setIsView: React.Dispatch<React.SetStateAction<boolean>>;
  company: string;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  officePostCode: string;
  setOfficePostCode: React.Dispatch<React.SetStateAction<string>>;
  officeAddress: string;
  setOfficeAddress: React.Dispatch<React.SetStateAction<string>>;
  officeAddressDetail: string;
  setOfficeAddressDetail: React.Dispatch<React.SetStateAction<string>>;
  owner: string;
  setOwner: React.Dispatch<React.SetStateAction<string>>;
  brokerageNumber: string;
  setBrokerageNumber: React.Dispatch<React.SetStateAction<string>>;
  businessNumber: string;
  setBusinessNumber: React.Dispatch<React.SetStateAction<string>>;
  sIdx: string;
  setSIdx: React.Dispatch<React.SetStateAction<string>>;
  deleteImg: string[];
  setDeleteImg: React.Dispatch<React.SetStateAction<string[]>>;
}

const SaleItemContext = createContext<SaleItemContextTypes | undefined>(
  undefined,
);

export const SaleItemProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [registrant, setRegistrant] = useState<string>('');
  const [isHolder, setIsHolder] = useState('N');
  const [type, setType] = useState<string>('');
  const [addressList, setAddressList] = useState<AddressListProps[]>([
    {
      postCode: '',
      jibunAddress: '',
      detail: '',
    },
  ]);
  const [isAddressPublic, setIsAddressPublic] = useState<string>('');
  const [saleItemImages, setSaleItemImages] = useState<File[]>([]);
  const [imgList, setImgList] = useState<string[]>([]);
  const [saleItemImagesURLs, setSaleItemImagesURLs] = useState<string[]>([]);
  const [explanation, setExplanation] = useState<string>('');
  const [detailExplanation, setDetailExplanation] = useState<string>('');
  const [area, setArea] = useState('');
  const [expectedAmount, setExpectedAmount] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isView, setIsView] = useState<boolean>(false);
  const [company, setCompany] = useState<string>('');
  const [officePostCode, setOfficePostCode] = useState<string>('');
  const [officeAddress, setOfficeAddress] = useState<string>('');
  const [officeAddressDetail, setOfficeAddressDetail] = useState<string>('');
  const [owner, setOwner] = useState<string>('');
  const [brokerageNumber, setBrokerageNumber] = useState<string>('');
  const [businessNumber, setBusinessNumber] = useState<string>('');
  const [sIdx, setSIdx] = useState<string>('');
  const [deleteImg, setDeleteImg] = useState<string[]>([]);

  return (
    <SaleItemContext.Provider
      value={{
        registrant,
        setRegistrant,
        isHolder,
        setIsHolder,
        type,
        setType,
        addressList,
        setAddressList,
        isAddressPublic,
        setIsAddressPublic,
        saleItemImages,
        setSaleItemImages,
        imgList,
        setImgList,
        saleItemImagesURLs,
        setSaleItemImagesURLs,
        explanation,
        setExplanation,
        detailExplanation,
        setDetailExplanation,
        area,
        setArea,
        expectedAmount,
        setExpectedAmount,
        name,
        setName,
        phone,
        setPhone,
        isView,
        setIsView,
        company,
        setCompany,
        officePostCode,
        setOfficePostCode,
        officeAddress,
        setOfficeAddress,
        officeAddressDetail,
        setOfficeAddressDetail,
        owner,
        setOwner,
        brokerageNumber,
        setBrokerageNumber,
        businessNumber,
        setBusinessNumber,
        sIdx,
        setSIdx,
        deleteImg,
        setDeleteImg,
      }}
    >
      {children}
    </SaleItemContext.Provider>
  );
};

export const useSaleItemContext = (): SaleItemContextTypes => {
  const context = useContext(SaleItemContext);
  if (!context) {
    throw new Error(
      'useSaleItemContext must be used within a SaleItemProvider',
    );
  }
  return context;
};
