import styled from 'styled-components';
import { useSaleItemContext } from '../../contexts/SaleItemContext';
import Sales from '../../service/Sales';

interface InfoConfirmModalProps {
  setOpenConfirmModal: (value: boolean) => void;
  setOpenCompletionModal: (value: boolean) => void;
}

const InfoConfirmModal = ({
  setOpenConfirmModal,
  setOpenCompletionModal,
}: InfoConfirmModalProps) => {
  const {
    registrant,
    isHolder,
    type,
    name,
    phone,
    area,
    expectedAmount,
    company,
    owner,
    officeAddress,
    officeAddressDetail,
    brokerageNumber,
    businessNumber,
    addressList,
    saleItemImages,
    explanation,
    detailExplanation,
    isAddressPublic,
    officePostCode,
    setRegistrant,
    setIsHolder,
    setType,
    setAddressList,
    setIsAddressPublic,
    setSaleItemImages,
    setSaleItemImagesURLs,
    setExplanation,
    setDetailExplanation,
    setArea,
    setExpectedAmount,
    setName,
    setPhone,
    setIsView,
    setCompany,
    setOfficePostCode,
    setOfficeAddress,
    setOfficeAddressDetail,
    setOwner,
    setBrokerageNumber,
    setBusinessNumber,
  } = useSaleItemContext();

  const BasicInfo = [
    {
      title: '등록인',
      content:
        registrant === 'B' && isHolder === 'Y'
          ? '개인 소유주'
          : registrant === 'B'
            ? '개인'
            : '부동산',
    },
    {
      title: '매물 유형',
      content: type === 'L' ? '토지' : '주택',
    },
    {
      title: '이름',
      content: name,
    },
    {
      title: '연락처',
      content: phone,
    },
    {
      title: '매매 면적',
      content: Number(area).toLocaleString() + '㎡',
    },
    {
      title: '희망 매매가',
      content: Number(expectedAmount).toLocaleString() + '원',
    },
  ];

  const AgentInfo = [
    {
      title: '상호명',
      content: company,
    },
    {
      title: '대표자 이름',
      content: owner,
    },
    {
      title: '사무소 주소',
      content: officeAddress + ' ' + officeAddressDetail,
    },
    {
      title: '부동산 중개업 등록번호',
      content: brokerageNumber,
    },
    {
      title: '사업자 번호',
      content: businessNumber,
    },
  ];

  const RegisterSaleItem = async () => {
    const companyAddress = {
      postCode: officePostCode,
      jibunAddress: officeAddress,
      detail: officeAddressDetail,
    };
    try {
      const formData = new FormData();
      formData.append('addressInfo', JSON.stringify(addressList));
      formData.append('ownerType', isHolder === 'Y' ? 'A' : registrant);
      formData.append('type', type);
      saleItemImages.forEach((file) => {
        formData.append('img', file);
      });
      formData.append('descTitle', explanation);
      formData.append('descDetail', detailExplanation);
      formData.append('area', area);
      formData.append('price', expectedAmount);
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('addrYn', isAddressPublic);
      formData.append('bCompany', company);
      formData.append('bAddress', JSON.stringify(companyAddress));
      formData.append('bName', owner);
      formData.append('bId', brokerageNumber);
      formData.append('bBizId', businessNumber);

      const res = await Sales.registerSaleItem(formData);

      if (res.data.statusCode === 201) {
        handleReset();
        setOpenConfirmModal(false);
        setOpenCompletionModal(true);
      } else {
        alert('매물 등록에 실패했습니다.');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRegisterButtonClick = () => {
    RegisterSaleItem();
  };

  const handleReset = () => {
    setRegistrant('');
    setIsHolder('N');
    setType('');
    setAddressList([
      {
        postCode: '',
        jibunAddress: '',
        detail: '',
      },
    ]);
    setIsAddressPublic('');
    setSaleItemImages([]);
    setSaleItemImagesURLs([]);
    setExplanation('');
    setDetailExplanation('');
    setArea('');
    setExpectedAmount('');
    setName('');
    setPhone('');
    setIsView(false);
    setCompany('');
    setOfficePostCode('');
    setOfficeAddress('');
    setOfficeAddressDetail('');
    setOwner('');
    setBrokerageNumber('');
    setBusinessNumber('');
  };

  return (
    <ModalContainer>
      <ModalInner>
        <TopClose>
          <img
            src="/asset/images/close/close24.svg"
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenConfirmModal(false);
            }}
          />
        </TopClose>
        <ContentBox>
          <ContentTitle>
            <span>선택하신 내용으로 등록할까요?</span>
            <ContentTitleText>
              등록 후 마이촌에서 수정 가능해요
            </ContentTitleText>
          </ContentTitle>
          <ContentInfoBox>
            {BasicInfo.map((item, index) => {
              return (
                <InfoItem
                  key={index}
                  style={{
                    paddingBottom: index === BasicInfo.length - 1 ? '12px' : 0,
                    borderBottom:
                      index === BasicInfo.length - 1
                        ? '1px solid rgba(112, 115, 124, 0.16)'
                        : 'none',
                  }}
                >
                  <span>{item.title}</span>
                  <InfoItemText>{item.content}</InfoItemText>
                </InfoItem>
              );
            })}
            {registrant === '부동산' && (
              <AgentInfoBox>
                {AgentInfo.map((item, index) => {
                  const isSpecialTitle = [
                    '사무소 주소',
                    '부동산 중개업 등록번호',
                    '사업자 번호',
                  ].includes(item.title);

                  return (
                    <InfoItem
                      key={index}
                      style={{
                        paddingBottom:
                          index === AgentInfo.length - 1 ? '12px' : 0,
                        borderBottom:
                          index === AgentInfo.length - 1
                            ? '1px solid rgba(112, 115, 124, 0.16)'
                            : 'none',
                        display: 'flex',
                        flexDirection: isSpecialTitle ? 'column' : 'row',

                        gap: isSpecialTitle ? '8px' : '0',
                        justifyContent: isSpecialTitle
                          ? 'flex-start'
                          : 'space-between',
                        alignItems: isSpecialTitle ? 'flex-start' : 'center',
                      }}
                    >
                      <span>{item.title}</span>
                      <InfoItemText>{item.content}</InfoItemText>
                    </InfoItem>
                  );
                })}
              </AgentInfoBox>
            )}

            {addressList.map((item: any, index: number) => {
              return (
                <AddressInfoItem key={index}>
                  <span>
                    매물 상세 주소{addressList.length > 1 ? index + 1 : ''}
                  </span>
                  <span
                    style={{
                      fontFamily: 'Pretendard-Bold',
                      color: '#1E9B6B',
                    }}
                  >
                    {item.jibunAddress + ' '}
                    {item.detail}
                  </span>
                </AddressInfoItem>
              );
            })}
          </ContentInfoBox>
          <ButtonBox>
            <Button
              style={{
                color: '#1E9B6B',
                border: '1px solid rgba(112, 115, 124, 0.22)',
              }}
              onClick={() => {
                setOpenConfirmModal(false);
              }}
            >
              내용 수정하기
            </Button>
            <Button
              style={{ color: 'white', backgroundColor: '#1E9B6B' }}
              onClick={handleRegisterButtonClick}
            >
              등록하기
            </Button>
          </ButtonBox>
        </ContentBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default InfoConfirmModal;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: inherit;
  z-index: 100;
`;
const ModalInner = styled.div`
  width: 312px;
  padding: 24px 16px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;
const TopClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ContentTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ContentTitleText = styled.span`
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;

const ContentInfoBox = styled.div`
  width: 100%;
  max-height: 358px;
  overflow-y: auto;
  padding: 16px;
  border-radius: 16px;
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;
const AddressInfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;

const InfoItemText = styled.span`
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #222;
`;
const AgentInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
`;
const Button = styled.span`
  flex: 1;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;
