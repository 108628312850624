import { useEffect, useRef, useState } from 'react';
import {
  Container,
  MyInfoBox,
  PageTitle,
  ProfileBox,
  PointBox,
  Profile,
  Name,
  Img,
  MyInfoButton,
  MyPointInfo,
  PointText,
  PointButtonBox,
  PointButton,
  TabMenuBox,
  TabMenuInner,
  TabMenu,
  NoImg,
} from './MyChon.styles';
import BottomNavigation from '../components/navigation/Navigation';
import { isLogin } from '../../utilities/auth';
import InterestingItems from './InterestingItems';
import MyItems from './MyItems';
import LatestSearchItem from './LatestSearchItem';
import styled from 'styled-components';
import { LikeProps } from './data';
import Interaction from '../../service/interaction';
import Loading from '../components/loading/Loading';
import { useLocation, useNavigate } from 'react-router-dom';

const MyChon = () => {
  const [name, setName] = useState('');
  const [point, setPoint] = useState(0);
  const [profileImg, setProfileImg] = useState<string | null>(null);
  const [tab, setTab] = useState('관심');
  const [sort, setSort] = useState('A');
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState<LikeProps[]>([]);
  const [isChanged, setIsChanged] = useState(false);
  const navigate = useNavigate();
  const [saleItemImgType, setSaleItemImgType] = useState('text');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get('tab');

  useEffect(() => {
    if (tabValue === 'saleItem') {
      setTab('매물관리');
    }
  }, []);

  const getUserInfo = async () => {
    try {
      setName(localStorage.getItem('name') ?? '');
      setPoint(Number(localStorage.getItem('point')) ?? 0);
      setProfileImg(localStorage.getItem('profileImg') ?? null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLogin()) {
      getUserInfo();
    }
  }, [isLogin]);

  const getList = async (sort: string, intType: string) => {
    setLoading(true);
    try {
      const result = await Interaction.list(sort, intType);
      setListData(result.result);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    const type = tab === '관심' ? 'A' : 'B';
    getList(sort, type);
  }, [sort, tab, isChanged]);

  const renderTabContent = () => {
    switch (tab) {
      case '관심':
        return (
          <InterestingItems
            data={listData}
            sort={sort}
            setSort={setSort}
            setIsChanged={setIsChanged}
          />
        );
      case '최근 본 주소':
        return <LatestSearchItem data={listData} />;
      case '매물관리':
        return <MyItems />;
      default:
        return (
          <InterestingItems
            data={listData}
            sort={sort}
            setSort={setSort}
            setIsChanged={setIsChanged}
          />
        );
    }
  };

  //위로가기 버튼 기능
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showButton, setShowButton] = useState(false);
  const onClickTopButton = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        // console.log('scrollTop', scrollTop);
        setShowButton(scrollTop >= 400); // 스크롤이 400px 이상내려갔을 때 버튼 표시
        setSaleItemImgType('img');
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <PageTitle>마이촌</PageTitle>
      <MyInfoBox>
        <ProfileBox>
          <Profile>
            {profileImg && profileImg !== 'null' ? (
              <Img
                src={`https://back.transfarmer.kr/img/${profileImg}`}
                alt=""
              />
            ) : (
              <NoImg></NoImg>
            )}

            <p>
              <Name>{name} </Name>님,반갑습니다!
            </p>
          </Profile>
          <MyInfoButton
            onClick={() => {
              navigate('/myinfo');
            }}
          >
            내 정보
          </MyInfoButton>
        </ProfileBox>
      </MyInfoBox>
      <TabMenuBox>
        <TabMenuInner>
          <TabMenu
            active={tab === '관심'}
            onClick={() => {
              setTab('관심');
            }}
          >
            관심
          </TabMenu>
          <TabMenu
            active={tab === '최근 본 주소'}
            onClick={() => {
              setTab('최근 본 주소');
            }}
          >
            최근 본 주소
          </TabMenu>
          <TabMenu
            active={tab === '매물관리'}
            onClick={() => {
              setTab('매물관리');
            }}
          >
            매물관리
          </TabMenu>
        </TabMenuInner>
      </TabMenuBox>
      {renderTabContent()}
      {tab === '매물관리' ? (
        <>
          {showButton ? (
            <TopButton location="top">
              <img
                onClick={onClickTopButton}
                style={{ paddingRight: '16px' }}
                src="./asset/images/arrow/arrow-to-top.png"
                alt=""
              />
            </TopButton>
          ) : null}
          <TopButton location="bottom">
            <img
              onClick={() => {
                navigate('/register/property');
              }}
              src={`./asset/images/icon/${saleItemImgType === 'text' ? 'propertyRegistration' : 'propertyRegistrationImg'}.png`}
              alt=""
              style={{
                paddingRight: saleItemImgType === 'text' ? '0px' : '6px',
              }}
            />
          </TopButton>
        </>
      ) : (
        showButton && (
          <TopButton location="bottom">
            <img
              onClick={onClickTopButton}
              src="./asset/images/arrow/arrow-to-top.png"
              alt=""
            />
          </TopButton>
        )
      )}
      <BottomNavigation />
      {loading && <Loading />}
    </Container>
  );
};

interface TopButtonProps {
  location: string;
}

const TopButton = styled.div<TopButtonProps>`
  position: sticky;
  bottom: ${(props) => (props.location === 'top' ? '126px' : '70px')};
  right: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  pointer-events: none; /* 아래 div 클릭 가능 */

  & > * {
    pointer-events: auto; /* 자식 요소는 여전히 클릭 가능 */
  }
`;

export default MyChon;
