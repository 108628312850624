import styled from 'styled-components';

interface CallAlertProps {
  setCallOn: (value: boolean) => void;
  brokerInfo: any;
  region: string;
}

const CallAlert = ({ setCallOn, brokerInfo, region }: CallAlertProps) => {
  return (
    <Container
      onClick={() => {
        setCallOn(false);
      }}
    >
      <Inner>
        <PageTop>
          <img
            src="./asset/images/close/close-gray.svg"
            alt=""
            onClick={() => {
              setCallOn(false);
            }}
          />
        </PageTop>
        <ContentBox>
          <BrokerInfoBox>
            <BrokerInfoHeaderBox>
              <div>
                <span>{region}</span>
              </div>
              <div>부동산 안심 중개 파트너</div>
            </BrokerInfoHeaderBox>
            <BrokerInfoInner>
              <BrokerInfo>
                <img
                  src={
                    brokerInfo.profileImg && brokerInfo.profileImg !== ''
                      ? brokerInfo.profileImg
                      : './asset/images/icon/broker_default_img.png'
                  }
                ></img>
                <BrokerCompany>
                  <CompanyName>{brokerInfo.company}</CompanyName>
                  <div>
                    대표 <span>{brokerInfo.name}</span>
                  </div>
                </BrokerCompany>
              </BrokerInfo>
              <Divider></Divider>
              <BrokerCall>
                <div>
                  <img
                    src="./asset/images/icon/green_call.svg"
                    style={{ width: '24px', height: '24px' }}
                    alt=""
                  />
                </div>
                <div>{brokerInfo.phone}</div>
              </BrokerCall>
            </BrokerInfoInner>
            <NoticeBox>
              <div>
                <span>트랜스파머에서 보고 연락했다고 말씀해주세요!</span>
              </div>
              <div>
                {' '}
                트랜스파머와 함께하는 지역 전문 파트너 공인중개사가
                <div>안심거래를 도와드립니다.</div>
              </div>
            </NoticeBox>
          </BrokerInfoBox>
          <ButtonBox
            onClick={() => {
              setCallOn(false);
            }}
          >
            확인
          </ButtonBox>
        </ContentBox>
      </Inner>
    </Container>
  );
};

export default CallAlert;

const ButtonBox = styled.div`
  width: 100%;
  height: 50px;
  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26.4px;
  word-wrap: break-word;
  border: 1px rgba(112, 115, 124, 0.22) solid;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoticeBox = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  span {
    color: #358cff;
    font-weight: 700;
  }
  align-items: center;
`;

const BrokerCall = styled.div`
  color: black;
  font-size: 18px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  gap: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(112, 115, 124, 0.16);
`;

const CompanyName = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

const BrokerCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #222222;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
  span {
    color: #616161;
  }
`;

const BrokerInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const BrokerInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f7f8f9;
  img {
    width: 42px;
    height: 42px;
  }
`;

const BrokerInfoHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: black;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  span {
    color: #1e9b6b;
  }
`;

const BrokerInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 24px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  max-width: 720px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.52);
`;

const Inner = styled.div`
  width: 312px;
  height: 417px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  padding: 24px 16px;
`;

const PageTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;
