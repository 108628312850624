import styled from 'styled-components';
import colors from '../../color';

export const Container = styled.div`
  max-width: 720px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  box-shadow:
    0 0 30px hsla(0, 0%, 51%, 0.15),
    inset 0 0 10px hsla(0, 0%, 51%, 0.1);
`;

export const PageTop = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;

export const ContentBox = styled.div`
  max-width: 720px;
  padding: 42px 16px 72px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  position: relative;
`;

export const Text = styled.div`
  font-family: Pretendard-Bold;
  font-size: 32px;
  color: #000;
  line-height: 145%;
  letter-spacing: -0.32px;
  text-align: center;
`;

export const ImgageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 72px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
  padding-bottom: 166px;
`;
export const ImgageItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;
export const GifBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(112, 115, 124, 0.16);
`;

export const ImgTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
