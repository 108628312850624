import styled from 'styled-components';

const PageTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Inner = styled.div`
  width: 312px;
  // height: 461px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 16px 24px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  max-width: 720px;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.52);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Button = styled.div`
  border-radius: 8px;
  border: 1px rgba(112, 115, 124, 0.22) solid;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 12px 16px;

  color: #222222;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 26.4px;
  word-wrap: break-word;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: black;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  span {
    color: #1e9b6b;
  }
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const NoticeInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #358cff;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 14px;
  word-wrap: break-word;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(55, 56, 60, 0.61);
    font-weight: 500;
  }
`;

const BrokerInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #f7f8f9;
  border-radius: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

const BrokerInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f7f8f9;
  img {
    width: 42px;
    height: 42px;
  }
`;

const BrokerInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
const BrokerCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #222222;
  font-size: 12px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
  span {
    color: #616161;
  }
`;

const BrokerCall = styled.div`
  color: black;
  font-size: 18px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  gap: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(112, 115, 124, 0.16);
`;

const CompanyName = styled.div`
  color: #222222;
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;
export const S = {
  PageTop,
  Inner,
  Container,
  TextWrapper,
  Button,
  DescriptionWrapper,
  Title,
  TitleBox,
  NoticeInfoBox,
  BrokerInfoWrapper,
  BrokerInfoInner,
  CompanyName,
  BrokerInfo,
  BrokerCompany,
  BrokerCall,
  Divider,
};
