import styled from 'styled-components';
import { formatToKoreanCurrency } from '../../../../../function/function';

interface TextColorProps {
  color: string;
  backgroundColor: string;
}

const TextColor = (dataType: string): TextColorProps => {
  switch (dataType) {
    case '매물':
      return { color: '#EC4F4F', backgroundColor: '#FFEDED' };
    case '경매':
      return { color: '#3160D6', backgroundColor: '#EBEFFA' };
    case '공매':
      return { color: '#7343E7', backgroundColor: '#F2EBFD' };
    case '지자체 물건':
      return {
        color: '#F47A00',
        backgroundColor: '#FDF6EE',
      };
    case '인터넷 매물':
      return {
        color: '#C28200',
        backgroundColor: '#FFF9E6',
      };
    case '농지은행':
      return { color: '#F47A00', backgroundColor: '#FDF6EE' };
    case '복합필지':
      return { color: '#FB43B5', backgroundColor: '#F9ECF4' };
    default:
      return {
        color: 'rgba(55, 56, 60, 0.61)',
        backgroundColor: '#f5f5f5',
      };
  }
};
const landType = (type: string) => {
  if (type !== 'L') {
    return (
      <>
        <TitleContent {...TextColor('')}>주택</TitleContent>
      </>
    );
  } else {
    return (
      <>
        <TitleContent {...TextColor('')}>토지</TitleContent>
      </>
    );
  }
};
const formatDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-'); // "2024-01-12"을 "-"로 분리
  return `${year.slice(2)}.${month}.${day}`; // 년도는 뒤 두 자리만, 월과 일은 그대로 사용
};

const renderTitleInfo = ({
  saleType,
  type,
  caseNo,
  court,
  price,
  dealdate,
  sale_idx,
  is_multy,
}: SummaryTitleProps) => {
  if (saleType === '매물') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {is_multy === '복합' ? (
          <TitleContent {...TextColor('복합필지')}>복합필지</TitleContent>
        ) : (
          ''
        )}
        {landType(type)}
        <TitleContent {...TextColor('매물번호')}>
          매물번호 {sale_idx}
        </TitleContent>
      </TitleContentBox>
    );
  } else if (saleType === '경매' || saleType === '공매') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {landType(type)}
        <TitleContent {...TextColor('')}>{caseNo}</TitleContent>
      </TitleContentBox>
    );
  } else if (saleType === '지자체 물건') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        <>
          <TitleContent {...TextColor('')}>농지은행</TitleContent>
          <TitleContent {...TextColor('')}>토지</TitleContent>
        </>
      </TitleContentBox>
    );
  } else if (saleType === '인터넷 매물') {
    return (
      <TitleContentBox>
        <TitleContent {...TextColor(saleType)}>{saleType}</TitleContent>
        {landType(type)}
      </TitleContentBox>
    );
  } else {
    return (
      <RealDealBox>
        <RealDealDataBox>
          <RealDealContentBox>
            <div>최근실거래</div>
          </RealDealContentBox>
          {price && price > 0 ? (
            <RealDealPriceData>
              {formatToKoreanCurrency(price)}
            </RealDealPriceData>
          ) : (
            <RealDealPriceData
              style={{
                color: 'rgba(55,56,60,0.28)',
              }}
            >
              실거래가 없음
            </RealDealPriceData>
          )}
        </RealDealDataBox>
        {price && price > 0 ? (
          <RealDealDate>
            최근 실거래일 {dealdate && formatDate(dealdate)}
          </RealDealDate>
        ) : (
          <RealDealDate></RealDealDate>
        )}
      </RealDealBox>
    );
  }
};

interface SummaryTitleProps {
  saleType: string;
  type: string;
  price?: number;
  caseNo?: string;
  court?: string;
  dealdate?: string;
  sale_idx?: string;
  is_multy?: string;
}

const SummaryTitle = ({
  saleType,
  type,
  caseNo,
  court,
  price,
  dealdate,
  sale_idx,
  is_multy,
}: SummaryTitleProps) => {
  return (
    <SummaryTitleBox>
      {renderTitleInfo({
        saleType,
        type,
        caseNo,
        court,
        price,
        dealdate,
        sale_idx,
        is_multy,
      })}
    </SummaryTitleBox>
  );
};
export default SummaryTitle;

const SummaryTitleBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  width: 100%;
`;

const TitleContentBox = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

const TitleContent = styled.div<TextColorProps>`
  justify-content: center;

  align-items: center;
  gap: 8px;
  border-radius: 4px;
  display: inline-flex;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  padding: 2px 6px;
`;

const RealDealBox = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 71px;
  padding: 12px 16px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RealDealDataBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const RealDealContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #616161;
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;

const RealDealPriceData = styled.div`
  color: #222222;
  font-size: 20px;
  font-family: Pretendard-Bold;
  line-height: 29px;
  word-wrap: break-word;
`;

const RealDealDate = styled.div`
  display: flex;
  justify-content: flex-end;
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;
`;
