import styled from 'styled-components';

export const Button2 = ({
  children,
  description,
  onClick,
  on,
  normal,
}: any) => {
  return (
    <div
      className={`hover w-100per padding-14-16 center-x center-y gap-10 flex-row bg-FFFFFF `}
      style={{
        border: !on ? '1px solid rgba(55, 56, 60, 0.16)' : 'none',
        background: on ? 'rgba(30, 155, 107, 1)' : 'white',
        borderRadius: normal ? '8px' : '100px',
      }}
      onClick={onClick}
    >
      {children}
      {description && (
        <div
          className="font-bold f-size-16 line-h-26 "
          style={{
            color:
              description === '취소'
                ? 'rgba(152, 155, 162, 1)'
                : !on
                  ? 'rgba(55, 56, 60, 0.16)'
                  : 'white',
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export const Button = ({
  children,
  description,
  onClick,
  on,
  menu,
  loading,
  type,
}: any) => {
  return (
    <ButtonBox
      isOn={on}
      description={description}
      // className={`${
      //   menu === 'idfind' ? (on ? `hover` : ``) : `hover`
      // } w-100per padding-14-16 center-x center-y gap-10 flex-row border-radius-8 ${
      //   !on ? 'white' : 'bg-primary-500'
      // }`}
      type={type}
      onClick={onClick}
    >
      {children}
      {loading ? (
        <img
          src="/asset/images/icon/loading-white.svg"
          alt="Loading"
          className="loading-icon"
        />
      ) : (
        description && (
          <>
            <DescriptionText isOn={on} type={type}>
              {description}
            </DescriptionText>
          </>
        )
      )}
    </ButtonBox>
  );
};

interface InputProps {
  isOn: boolean;
  description?: string;
  type?: string;
}

const DescriptionText = styled.div<InputProps>`
  color: ${(props) =>
    props.isOn
      ? 'white'
      : props.type === 'deleteModal'
        ? 'rgba(30, 155, 107, 1)'
        : '#989BA2'};

  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const ButtonBox = styled.div<InputProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 14px 16px;
  gap: 10px;
  align-items: center;
  border-radius: 8px;

  color: ${(props) => {
    if (
      props.description === '탈퇴하기' ||
      props.description === '다음' ||
      props.description === '네' ||
      props.description === '거래완료'
    ) {
      return props.isOn ? 'white' : 'rgba(55, 56, 60, 0.16)';
    } else {
      return 'rgba(152, 155, 162, 1)';
    }
  }};

  background-color: ${(props) => {
    if (
      props.description === '탈퇴하기' ||
      props.description === '다음' ||
      props.description === '네' ||
      props.description === '거래완료'
    ) {
      return props.isOn ? 'rgba(30, 155, 107, 1)' : 'rgba(247, 247, 248, 1)';
    } else {
      return 'white';
    }
  }};

  cursor: pointer;
  border: 1px rgba(55, 56, 60, 0.16) solid;
  border: ${(props) =>
    props.isOn ? 'none' : '1px rgba(55, 56, 60, 0.16) solid'};
`;
