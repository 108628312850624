import styled from 'styled-components';
import colors from '../../color';
import BottomNavigation from '../components/navigation/Navigation';
import MoreInfo from './components/MoreInfo';
import MoreContent from './components/MoreContent';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMapContext } from '../../contexts/MapContext';

const More = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showButton, setShowButton] = useState(false);
  const onClickTopButton = () => {
    if (!containerRef.current) return;
    containerRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const scrollTop = containerRef.current.scrollTop;
        setShowButton(scrollTop >= 100); // 스크롤이 100px 이상일 때 버튼 표시
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    // 클린업 함수
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const { latLng } = useMapContext();

  return (
    <Container ref={containerRef}>
      <Header>
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            if (!localStorage.getItem('lat') || !localStorage.getItem('lng')) {
              const latitude = latLng?.lat ?? 0; // 기본값을 0으로 설정
              const longitude = latLng?.lng ?? 0;
              navigate(`/?lat=${latitude}&lng=${longitude}`);
            } else {
              navigate(
                `/?lat=${localStorage.getItem('lat')}&lng=${localStorage.getItem('lng')}`,
              );
            }
          }}
          style={{ cursor: 'pointer' }}
        />
        <div>더보기</div>
      </Header>
      <MoreInfo></MoreInfo>
      <Divider></Divider>
      <MoreContent></MoreContent>
      {showButton && (
        <TopButton>
          <img
            onClick={onClickTopButton}
            src="/asset/images/arrow/arrow-to-top.png"
            alt=""
          />
        </TopButton>
      )}
      <BottomNavigation />
    </Container>
  );
};

export default More;

const Container = styled.div`
  max-width: 720px;
  height: 100dvh;
  position: relative;
  overflow-x: hidden; /* 가로 스크롤만 숨기기 */
  overflow-y: scroll; /* 필요할 때만 세로 스크롤 허용 */
  background-color: ${colors.white};
`;

const Header = styled.div`
  width: 100%;
  height: 51px;
  padding: 15px 16px;
  color: #222222;
  font-size: 18px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 8px;
  background-color: #f5f5f5;
`;

const TopButton = styled.div`
  position: sticky;
  bottom: 70px;
  right: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;
  pointer-events: none; /* 아래 div 클릭 가능 */

  & > * {
    pointer-events: auto; /* 자식 요소는 여전히 클릭 가능 */
  }
`;
