import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './App.css';
import Main from './page/main/Main';
import More from './page/more/More';
import Detail from './page/detail/Detail';
import Brokerage from './page/brokerage/Brokerage';
import PensionInput from './page/detail/components/pension/input/PnesionInput';
import PensionReportMain from './page/detail/components/pension/reportResultPension/Main';
import HouseInput from './page/detail/components/house/input/house/report/Report';
import HouseReportMain from './page/detail/components/house/reportResultHouse/Main';
import Property from './page/property/Property';
import LandIncomeInput from './page/detail/components/landIncome/input/LandIncomeInput';
import LandIncomeResult from './page/detail/components/landIncome/result/LandIncomeResult';
import Login from './page/login/Login';
import SignUp from './page/signup/Signup';
import SignUpSns from './page/signup/SignUpSns';
import SignUpEmail from './page/signup/SignUpEmail';
import MyChon from './page/mychon/MyChon';
import { FilterProvider } from './contexts/FilterContext';
import { MapProvider } from './contexts/MapContext';
import { PropertyFilterProvider } from './contexts/PropertyContext';
import Inquiry from './page/inquiry/Inquiry';
import NoticeList from './page/notice/Notice';
import NoticeDetail from './page/notice/NoticeDetail';
import CompanyInfo from './page/companyInfo/CompanyInfo';
import NewsRoom from './page/newsRoom/NewsRoom';
import NewsRoomDetail from './page/newsRoom/NewsRoomDetail';
import ServiceTerms from './page/terms/Service';
import PrivacyTerms from './page/terms/Privacy';
import LocationTerms from './page/terms/Location';
import MyInfo from './page/myInfo/MyInfo';
import NotificationInfo from './page/terms/Notification';
import { SignUpInfoProvider } from './contexts/SignUpContext';
import AccountSetting from './page/account/AccountSetting';
import FindInfo from './page/findInfo/FindInfo';
import Delete from './page/delete/Delete';
import NewSearch from './page/search/NewSearch';
import { isLogin } from './utilities/auth';
import PriceLanding from './page/landing/Price';
import AnnuityLanding from './page/landing/Annuity';
import FindLandLanding from './page/landing/FindLand';
import AiFarmingLanding from './page/landing/AiFarming';
import MyHouseLanding from './page/landing/MyHouse';
import Consulting from './page/landing/consulting/Consulting';
import { HelmetProvider } from 'react-helmet-async';
import LandProduct from './page/landMarket/Product';
import LandApply from './page/landMarket/Apply';
import RegisterProperty from './page/registerProperty/RegisterProperty';
import { SaleItemProvider } from './contexts/SaleItemContext';
import EditProperty from './page/mychon/components/itemEdit/SaleItemEdit';
import { EditSaleItemProvider } from './contexts/EditSaleItemContext';

const ProtectedRoute = ({ children }: any) => {
  return isLogin() ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <HelmetProvider>
      <MapProvider>
        <PropertyFilterProvider>
          <FilterProvider>
            <SignUpInfoProvider>
              <SaleItemProvider>
                <EditSaleItemProvider>
                  <Router>
                    <div className="App">
                      <Routes>
                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path="/" element={<Main />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signUp" element={<SignUp />} />
                        <Route path="/sign/email" element={<SignUpEmail />} />
                        <Route path="/sign/sns" element={<SignUpSns />} />
                        <Route path="/search" element={<NewSearch />} />
                        <Route path="/more" element={<More />} />
                        <Route path="/detail" element={<Detail />} />
                        <Route path="/inquiry" element={<Inquiry />} />
                        <Route path="/notice" element={<NoticeList />} />
                        <Route
                          path="/notice/detail/:articleIdx"
                          element={<NoticeDetail />}
                        />
                        <Route path="/companyinfo" element={<CompanyInfo />} />
                        <Route path="/newsRoom" element={<NewsRoom />} />
                        <Route
                          path="/newsRoom/detail/:articleIdx"
                          element={<NewsRoomDetail />}
                        />
                        <Route
                          path="/terms/service"
                          element={<ServiceTerms />}
                        />
                        <Route
                          path="/terms/privacy"
                          element={<PrivacyTerms />}
                        />
                        <Route
                          path="/terms/location"
                          element={<LocationTerms />}
                        />
                        <Route
                          path="/terms/notification"
                          element={<NotificationInfo />}
                        />
                        <Route path="/find" element={<FindInfo />} />
                        <Route path="/property" element={<Property />} />
                        <Route path="/brokerage" element={<Brokerage />} />
                        <Route
                          path="/landMarket/product"
                          element={<LandProduct />}
                        />
                        <Route
                          path="/landMarket/apply"
                          element={<LandApply />}
                        />
                        <Route
                          element={<PriceLanding />}
                          path={`/promotion/정확한-토지-농지-주택-가격을-알아보는-방법`}
                        ></Route>
                        <Route
                          element={<AnnuityLanding />}
                          path={`/promotion/농지_가격_확인부터_농지연금_계획까지_한번에`}
                        ></Route>
                        <Route
                          element={<FindLandLanding />}
                          path={`/promotion/농사-수익과-비용분석-AI로-농업-경영-계획-세우기`}
                        ></Route>
                        <Route
                          element={<AiFarmingLanding />}
                          path={`/promotion/AI로-농장-진단-농업-경영-솔루션`}
                        ></Route>
                        <Route
                          element={<MyHouseLanding />}
                          path={`/promotion/전원주택-설계와-건축비-분석을-AI로-간편하게`}
                        ></Route>
                        <Route
                          element={<Consulting />}
                          path={`/promotion/트랜스파머-맞춤-토지-컨설팅`}
                        ></Route>
                        <Route
                          element={<Consulting />}
                          path={`/promotion/트랜스파머-맞춤-토지-컨설팅/:tab`}
                        ></Route>
                        <Route
                          path="/register/property"
                          element={<RegisterProperty />}
                        />
                        <Route
                          path="/edit/property/:saleIdx"
                          element={<EditProperty />}
                        />
                        {/* Protected Routes */}
                        <Route
                          path="/pension/input"
                          element={
                            <ProtectedRoute>
                              <PensionInput />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/pension/report"
                          element={
                            <ProtectedRoute>
                              <PensionReportMain />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/house/input"
                          element={
                            <ProtectedRoute>
                              <HouseInput />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/house/report"
                          element={
                            <ProtectedRoute>
                              <HouseReportMain />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/landIncome/input"
                          element={
                            <ProtectedRoute>
                              <LandIncomeInput />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/landIncome/result"
                          element={
                            <ProtectedRoute>
                              <LandIncomeResult />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="/mychon"
                          element={
                            <ProtectedRoute>
                              <MyChon />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/myinfo"
                          element={
                            <ProtectedRoute>
                              <MyInfo />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/account"
                          element={
                            <ProtectedRoute>
                              <AccountSetting />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/delete"
                          element={
                            <ProtectedRoute>
                              <Delete />
                            </ProtectedRoute>
                          }
                        />
                      </Routes>
                    </div>
                  </Router>
                </EditSaleItemProvider>
              </SaleItemProvider>
            </SignUpInfoProvider>
          </FilterProvider>
        </PropertyFilterProvider>
      </MapProvider>
    </HelmetProvider>
  );
}

export default App;
