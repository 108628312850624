import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface CancelModalProps {
  setShowCancelModal: (value: boolean) => void;
}

const CancelModal = ({ setShowCancelModal }: CancelModalProps) => {
  const navigate = useNavigate();
  return (
    <ModalContainer>
      <ModalInner>
        <TopClose>
          <img
            src="/asset/images/close/close24.svg"
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowCancelModal(false);
            }}
          />
        </TopClose>
        <ContentBox>
          <img
            src="/asset/images/icon/bang-mark.svg"
            alt=""
            style={{ width: '48px' }}
          />
          <TextBox>
            <div
              style={{
                fontFamily: 'Pretendard-Bold',
                fontSize: 16,
                color: '#000',
              }}
            >
              등록을 <span style={{ color: '#1E9B6B' }}>취소</span>하시겠어요?
            </div>
            <div>입력한 내용은 모두 사라져요</div>
          </TextBox>
          <ButtonBox>
            <Button
              style={{
                color: '#1E9B6B',
                border: '1px solid rgba(112, 115, 124, 0.22)',
              }}
              onClick={() => {
                setShowCancelModal(false);
              }}
            >
              계속작성
            </Button>
            <Button
              style={{ color: 'white', backgroundColor: '#1E9B6B' }}
              onClick={() => {
                navigate(-1);
              }}
            >
              네
            </Button>
          </ButtonBox>
        </ContentBox>
      </ModalInner>
    </ModalContainer>
  );
};

export default CancelModal;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.52);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: inherit;
  z-index: 100;
`;

const ModalInner = styled.div`
  width: 312px;
  padding: 24px 16px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
`;

const TopClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
const TextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;
const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
`;
const Button = styled.span`
  flex: 1;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
`;
