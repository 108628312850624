import styled from 'styled-components';

const SnackBar = () => {
  return (
    <SnackBarContainer>
      <Inner>주소지가 삭제되었습니다.</Inner>
    </SnackBarContainer>
  );
};

export default SnackBar;

const SnackBarContainer = styled.div`
  position: fixed;
  bottom: 110px;
  width: 100%;
  padding: 0 16px;
  max-width: inherit;
`;
const Inner = styled.div`
  width: 100%;
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(81, 85, 86, 0.92);
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(3px);
  border-radius: 12px;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  color: #fff;
`;
