import { useEffect, useState } from 'react';
import './Terms.css';
import TermsService from '../../service/terms';
import { useNavigate } from 'react-router-dom';
import ScrollToTopButton from '../components/toTopBtn/ScrollToTopButton';

interface ContentItem {
  seq: number;
  title: string;
  content:
    | {
        clause_idx: number;
        seq: number;
        title: string;
        content: {
          clause_idx: number;
          seq: number;
          title: string;
          content: any;
        }[];
      }[]
    | null;
  clause_idx: number;
}

interface Term {
  seq: number;
  title: string;
  content: ContentItem[];
  terms_idx: number;
}

interface ContentItem2 {
  seq: number;
  title: string;
  content: string | null;
  clause_idx: number;
}

interface Term2 {
  seq: number;
  title: string;
  content: ContentItem2[];
  terms_idx: number;
}

const ServiceTerms = () => {
  const navigate = useNavigate();

  const [verisionList, setVersionList] = useState<
    { version: 0; create_date: '' }[]
  >([]);

  const [selectedDate, setSelectedDate] = useState<{
    version: number;
    create_date: string;
  } | null>(null);

  const [terms, setTerms] = useState<Term[]>([]);
  const [addendum, setAddendum] = useState<Term2[]>([]);

  const getDateList = async () => {
    try {
      const res = await TermsService.version('T');
      setVersionList(res.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDateList();
  }, []);

  const getTerms = async (version: number) => {
    try {
      const res = await TermsService.terms(version, 'T');
      setAddendum(res.result.addendum);
      setTerms(res.result.list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      getTerms(selectedDate.version);
    } else if (verisionList.length > 0) {
      getTerms(verisionList[0].version);
    }
  }, [selectedDate, verisionList]);

  return (
    <div className="wrap">
      <ScrollToTopButton />
      <div className="page-top">
        <img
          src="/asset/images/arrow/arrow-left-gray.svg"
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
        <p>이용약관</p>
      </div>
      <div className="terms-inner">
        <div className="terms-title">
          트랜스파머 서비스 이용약관
          <div className="version-select">
            <select
              className="select-non-border"
              name=""
              id=""
              onChange={(e) => {
                const selectedVersion = verisionList.find(
                  (v) => v.version === Number(e.target.value),
                );
                if (selectedVersion) {
                  setSelectedDate(selectedVersion);
                }
              }}
              value={selectedDate?.version || ''}
            >
              {verisionList.map((item) => (
                <option key={item.version} value={item.version}>
                  {item.create_date}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="terms-content-box">
          {terms.map((term) => (
            <div className="terms-content-item" key={term.terms_idx}>
              <div
                className="terms-content-item-title"
                dangerouslySetInnerHTML={{
                  __html: term.title.replace(/\r\n/g, '<br>'),
                }}
              ></div>
              <div className="terms-content-item-text">
                {term.content.map((item) => (
                  <div key={item.clause_idx}>
                    <p>
                      {item.seq}. {item.title}
                    </p>
                    {item.content &&
                      item.content.map((content) => (
                        <div style={{ fontSize: '13px', marginLeft: '4px' }}>
                          {content.seq}
                          {')'} {content.title}
                          {content.content &&
                            content.content.map((c, idx) => (
                              <div key={idx}>- {c.title}</div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div>
            <div
              className="terms-content-item-title"
              style={{ marginBottom: '6px' }}
            >
              -부칙-
            </div>
            {addendum.map((term) => (
              <div className="terms-content-item" key={term.terms_idx}>
                <div
                  className="terms-content-item-title"
                  dangerouslySetInnerHTML={{
                    __html: term.title.replace(/\r\n/g, '<br>'),
                  }}
                ></div>
                <div className="terms-content-item-text">
                  {term.content.map((item) => (
                    <div key={item.clause_idx}>
                      <p>
                        {item.seq}. {item.title}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            typeof item.content === 'string'
                              ? item.content.replace(/\r\n/g, '<br>')
                              : '',
                        }}
                      ></p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTerms;
