import { useState } from 'react';
import Select, { SingleValue, components } from 'react-select';
const selectedOptions = [
  { value: 'A', label: '저장순' },
  { value: 'B', label: '높은 금액순' },
  { value: 'C', label: '낮은 금액순' },
];

const selectedOptions2 = [
  { value: 'D', label: '최신순' },
  { value: 'A', label: '오래된순' },
];

const selectedOptions3 = [
  { value: 'Y', label: '공개' },
  { value: 'N', label: '비공개' },
  { value: 'T', label: '거래완료' },
];

interface CustomSelectProps {
  onChange: (value: string) => void;
  value: string;
  type?: string;
  onClick?: () => void;
}

const SelectTypeBox = ({ onChange, value, type }: CustomSelectProps) => {
  const selectOption = (type?: string) => {
    switch (type) {
      case 'saleItem':
        return selectedOptions2;
      case 'itemStatus':
        return selectedOptions3;
      default:
        return selectedOptions;
    }
  };

  const selectBackgroundColor = (value: string, type?: string): string => {
    switch (type) {
      case 'saleItem':
        return 'transparent';
      case 'itemStatus':
        if (value === 'Y') {
          return '#EAF8F2';
        } else if (value === 'N') {
          return 'rgba(112, 115, 124, 0.05)';
        } else {
          return '#E8F2FF';
        }
      default:
        return '#f5f5f5';
    }
  };

  const TextAndArrowColor = (value: string, type?: string): string => {
    switch (type) {
      case 'saleItem':
        return '#222222';
      case 'itemStatus':
        if (value === 'Y') {
          return '#1E9B6B';
        } else if (value === 'N') {
          return 'rgba(55, 56, 60, 0.61)';
        } else {
          return '#358CFF';
        }
      default:
        return '#222222';
    }
  };

  const options = selectOption(type);

  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {props.label}
          {props.isSelected && (
            <img
              src="./asset/images/checkbox/check-green.svg"
              alt="Checked"
              style={{ marginLeft: '8px' }}
            />
          )}
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      options={options}
      value={options.find((option) => option.value === value)} // 선택된 값 설정
      onChange={(
        selectedOption: SingleValue<{ value: string; label: string }>,
      ) => {
        if (selectedOption) {
          onChange(selectedOption.value);
        } // selectedOption이 null이 아닐 때만 onChange 호출
      }}
      isSearchable={false}
      menuPlacement="bottom"
      menuPortalTarget={document.body} // 메뉴를 body에 렌더링
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // z-index 설정

        control: (base, state) => ({
          ...base,
          minWidth: type === 'saleItem' || type === 'itemStatus' ? '0' : '72px', // 전체 셀렉트 박스 너비
          minHeight: type === 'itemStatus' ? '28px' : '36px', // 전체 셀렉트 박스 너비
          backgroundColor: selectBackgroundColor(value, type), // 선택된 값의 배경색
          border: 'none',
          borderRadius: type === 'itemStatus' ? '8px' : '20px',
          boxShadow: state.isFocused ? 'none' : 'none', // 포커스 상태에서 그림자 없음
          color: TextAndArrowColor(value, type), // 텍스트 색상
          fontSize: '16px',
          fontFamily: 'Pretendard-medium',
          fontWeight: '500',
          lineHeight: '24px',
          wordWrap: 'break-word',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // padding: '4px 12px',
        }),
        valueContainer: (base) => ({
          // singleValue를 감싸는 컨테이너
          ...base,
          padding: 0, // 선택된 값의 컨테이너 패딩 제거
          width:
            type === 'saleItem'
              ? '67px'
              : type === 'itemStatus'
                ? '73px'
                : '100%',
        }),
        singleValue: (provided) => ({
          ...provided,
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          minWidth:
            type === 'saleItem' || type === 'itemStatus' ? '0px' : '74px', // 전체 셀렉트 박스 너비
          color: TextAndArrowColor(value, type), // 텍스트 색상
          fontSize:
            type === 'saleItem' || type === 'itemStatus' ? '14px' : '16px', // 텍스트 크기
          fontFamily: 'Pretendard-medium', // 폰트 패밀리
          fontWeight: '500', // 폰트 두께
          lineHeight: '24px', // 줄 높이
          margin: '0px',
          gridArea: '1',
          paddingRight: type === 'itemStatus' && value === 'T' ? '12px' : '0px',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: 'none',
          color: state.isSelected ? '#1E9B6B' : '#616161',
          padding: '10px 16px',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'baseline',
          fontSize: '16px',
          fontFamily: 'Pretendard-medium',
          fontWeight: '500',
          lineHeight: '24px',
          wordWrap: 'break-word',
        }),
        menu: (base) => ({
          ...base,
          minWidth: '184px', // 전체 옵션 박스의 최대 너비
          minHeight: type === 'saleItem' ? '100px' : '148px', // 전체 옵션 박스의 최대 높이
          overflowY: 'auto', // 내용이 넘칠 경우 스크롤 가능
          borderRadius: '8px',
          marginLeft:
            type === 'saleItem'
              ? '-110px'
              : type === 'itemStatus'
                ? '-113px'
                : '-86px',
          marginTop: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '8px',
        }),
        indicatorSeparator: () => ({ display: 'none' }), // 화살표 옆의 선 제거
        dropdownIndicator: (base) => ({
          ...base,
          color: TextAndArrowColor(value, type), // 화살표 색상 조정
          display: type === 'itemStatus' && value === 'T' ? 'none' : 'flex', // flex를 추가해 정렬 가능하게 설정
          justifyContent: 'center',
          alignSelf: 'baseline', // 세로 중앙 정렬
          padding: type === 'itemStatus' ? '4px 0px' : '8px 0px', // 화살표 패딩 제거
          paddingRight: type === 'itemStatus' ? '12px' : '0px',
        }),
        menuList: (base) => ({
          ...base,
          maxHeight: type === 'saleItem' ? '184px' : '192px', // 옵션 리스트 최대 높이
          overflowY: 'auto', // 스크롤 가능
          paddingTop: '0px',
          paddingBottom: '0px',
        }),
      }}
      components={{ Option: CustomOption }}
      openMenuOnClick={value !== 'T'}
    />
  );
};
export default SelectTypeBox;
