import styled from 'styled-components';
import { AnalysisProps } from '../../data';
import { TodayDate, transNum } from '../../../../function/function';
import colors from '../../../../color';
import PropertyChart from './PropertyChart';
import AuctionChart from './AuctionChart';
import NonPropertyChart from './NonPropertyChart';
import { useEffect, useState } from 'react';
import RealPriceChart from './RealPriceChart';
import { render } from '@testing-library/react';

interface AnalysisData {
  data: AnalysisProps;
  saleType: string;
  pyeong?: number;
  price?: number;
  dealDate?: string;
  itemType: string; //토지인지 건물인지
  is_multy: string;
}

const TAnalyze = ({
  data,
  saleType,
  pyeong,
  price,
  dealDate,
  itemType,
  is_multy,
}: AnalysisData) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const RenderText = (
    data: AnalysisProps,
    selectedType: string,
    is_multy: string,
  ) => {
    let gap = 0;

    if (selectedType === 'building') {
      gap = (data.house.price ?? 0) - (data.house.avg ?? 0);
    } else {
      gap = (data.land.price ?? 0) - (data.land.avg ?? 0);
    }
    if (
      saleType === '매물' ||
      saleType === '인터넷 매물' ||
      saleType === '지자체 물건'
    ) {
      if (gap > 0) {
        return (
          <DescriptionWrapper>
            {is_multy === '복합' && (
              <NoticeWrapper>
                <NoticeBox>
                  <div>※</div>
                  <div>분석은 대표 주소지만 제공합니다</div>
                </NoticeBox>
              </NoticeWrapper>
            )}
            <DescriptionContainer>
              <Left>
                <TopText>해당 매물은 기준 추정가보다</TopText>
                <BottomText>
                  <span>평당</span>
                  <BottomTextStrong>{transNum(gap)}원</BottomTextStrong>
                  <span>높아요</span>
                </BottomText>
              </Left>
            </DescriptionContainer>
          </DescriptionWrapper>
        );
      }
      if (gap < 0) {
        return (
          <DescriptionWrapper>
            {is_multy === '복합' && (
              <NoticeWrapper>
                <NoticeBox>
                  <div>※</div>
                  <div>분석은 대표 주소지만 제공합니다</div>
                </NoticeBox>
              </NoticeWrapper>
            )}
            <DescriptionContainer>
              <Left>
                <TopText>해당 매물은 기준 추정가보다</TopText>
                <BottomText>
                  <span>평당</span>{' '}
                  <BottomTextStrong style={{ color: '#358CFF' }}>
                    {transNum(gap)}원
                  </BottomTextStrong>
                  <span>낮아요</span>
                </BottomText>
              </Left>
            </DescriptionContainer>
          </DescriptionWrapper>
        );
      } else {
        return (
          <DescriptionWrapper>
            {is_multy === '복합' && (
              <NoticeWrapper>
                <NoticeBox>
                  <div>※</div>
                  <div>분석은 대표 주소지만 제공합니다</div>
                </NoticeBox>
              </NoticeWrapper>
            )}
            <DescriptionContainer>
              <Left>
                <TopText>해당 매물은 기준 추정가와</TopText>
                <BottomText>
                  <BottomTextStrong style={{ color: '#1E9B6B' }}>
                    같은 금액
                  </BottomTextStrong>
                  이에요
                </BottomText>
              </Left>
            </DescriptionContainer>
          </DescriptionWrapper>
        );
      }
    } else {
      return (
        <DescriptionContainer>
          <Left>
            <TopText>해당 주소지의 기준 추정가는</TopText>
            <BottomText>
              평당
              <BottomTextStrong style={{ color: 'black' }}>
                {transNum(
                  selectedType === 'building'
                    ? (data.house.avg ?? 0)
                    : (data.land.avg ?? 0),
                ) ||
                  transNum((price ?? 0) / (pyeong ?? 1)) ||
                  0}
                원
              </BottomTextStrong>
              수준이에요
            </BottomText>
          </Left>
        </DescriptionContainer>
      );
    }
  };

  const RenderChart = (saleType: string, selectedType: string) => {
    if (
      saleType === '매물' ||
      saleType === '인터넷 매물' ||
      saleType === '지자체 물건'
    ) {
      return (
        <PropertyChart
          data={data}
          pyeong={pyeong ?? 0}
          price={price ?? 0}
          selectedType={selectedType}
        />
      );
    } else if (saleType === '실거래') {
      return (
        <RealPriceChart
          data={data}
          pyeong={pyeong ?? 0}
          price={price ?? 0}
          dealDate={dealDate ?? ''}
          selectedType={selectedType}
        />
      );
    } else if (saleType === '경매' || saleType === '공매') {
      return (
        <AuctionChart
          data={data}
          pyeong={pyeong ?? 0}
          selectedType={selectedType}
        />
      );
    } else {
      return (
        <NonPropertyChart
          data={data}
          pyeong={pyeong ?? 0}
          selectedType={selectedType}
        />
      );
    }
  };

  const isProperty =
    saleType === '매물' ||
    saleType === '인터넷 매물' ||
    saleType === '지자체 물건' ||
    saleType === '실거래' ||
    saleType === '비매물';

  const isValidData = (data: {
    avg: number | null;
    high_avg: number | null;
    low_avg: number | null;
  }) => {
    return (
      data.avg !== null &&
      data.high_avg !== null &&
      data.low_avg !== null &&
      data.avg > 0 && // 추가 조건: avg는 양수여야 한다
      data.high_avg > 0 &&
      data.low_avg > 0
    );
  };

  const [selectedType, setSelectedType] = useState<'land' | 'building'>('land');
  useEffect(() => {
    setSelectedType(itemType === 'H' ? 'building' : 'land');
  }, [itemType]);
  const isData =
    itemType === 'H' ? isValidData(data.house) : isValidData(data.land);

  const ToolTip = () => {
    return (
      <TooltipBox>
        <TooltipTitle>
          <TooltipTitleText>
            <Dot></Dot>
            <span>T 시세 분석이란?</span>
          </TooltipTitleText>
          <img
            src="/asset/images/close/close.svg"
            alt=""
            onClick={() => {
              setShowTooltip(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </TooltipTitle>
        <TooltipTextBox>
          <div>해당 지역의 평당 평균가를 비교합니다.</div>
          <TooltipText>
            <TooltipLabel>상위 평균가</TooltipLabel>
            <div>
              가장 비싸게 거래된 매물들의 평당 평균가로,  고급 주택이나 높은
              매매가를 형성하는 매물들을 찾는 데 유용합니다.
            </div>
          </TooltipText>
          <TooltipText>
            <TooltipLabel>중위 평균가</TooltipLabel>
            <div>
              가장 많이 거래되는 일반적인 매물들의 평당 평균가로, 해당 지역의
              대표적인 평균 시세를 알고 싶을 때 유용합니다.
            </div>
          </TooltipText>
          <TooltipText>
            <TooltipLabel>하위 평균가</TooltipLabel>
            <div>
              비교적 저렴하게 거래된 매물들의 평당 평균가로, 상대적으로 저렴한
              매물을 찾거나 저가 시세대를 파악하는 데 유용합니다.
            </div>
          </TooltipText>
          <Caution>
            <CautionText>
              ※ 분석에 제공하는 주변 시세는 변동성이 있어 거래 시 주의가
              필요합니다.
            </CautionText>
          </Caution>
        </TooltipTextBox>
      </TooltipBox>
    );
  };

  return (
    <TAnalyzeContainer>
      <TAnalyzeTitleBox>
        <TAnalyzeTitle>
          T 시세 분석
          <img
            src="/asset/images/icon/information-outline.svg"
            alt=""
            onClick={() => {
              setShowTooltip(true);
            }}
          />
        </TAnalyzeTitle>
        <div>분석 일자 {TodayDate()}</div>
      </TAnalyzeTitleBox>
      {isProperty && isData && RenderText(data, selectedType, is_multy)}
      {isData && <>{RenderChart(saleType, selectedType)}</>}
      {!isData && (
        <NoDataMessage>
          주변 데이터가 부족해서 분석이 불가능합니다.
        </NoDataMessage>
      )}
      {showTooltip && <ToolTip />}
    </TAnalyzeContainer>
  );
};

export default TAnalyze;

interface TypeButtonProps {
  selected: boolean;
}

const TypeButton = styled.div<TypeButtonProps>`
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.selected ? 'rgba(112, 115, 124, 0.08)' : '#ffffff'};
  color: ${(props) =>
    props.selected
      ? '#222222'
      : '#616161'}; // 선택된 상태일 때 텍스트 색상 변경
  cursor: pointer;
`;

const TypeButtonBox = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const TAnalyzeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 16px;
  background-color: #ffffff;
  position: relative;
`;

const TAnalyzeTitleBox = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 12px;
  font-family: Pretendard-Medium;
  font-weight: 500;
  line-height: 14px;
  word-wrap: break-word;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const TAnalyzeTitle = styled.div`
  font-size: 20px;
  font-family: Pretendard-Medium;
  font-weight: 700;
  line-height: 24px;
  color: #222222;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TopText = styled.div`
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;
export const TopTextStrong = styled.span`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
export const BottomText = styled.div`
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: ${colors.labelNormal};
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
export const BottomTextStrong = styled.span`
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: #ec4f4f;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flaex-end;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 13px;
  color: ${colors.labelAlternative};
`;

export const Circle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #1e9b6b;
`;

const NoDataMessage = styled.div`
width: 100%;
padding 0 16px;
display: flex;
justify-content: center;
align-items: center;
font-family: Pretendard-Medium;
font-size: 16px;
color: ${colors.labelAlternative};
`;

const TooltipBox = styled.div`
  width: 280px;
  position: absolute;
  top: 58px;
  left: 16px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

const TooltipTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TooltipTitleText = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #222222;
`;
const Dot = styled.span`
  width: 2px;
  height: 2px;
  background-color: #616161;
`;

const TooltipTextBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222222;
`;

const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
`;

const TooltipLabel = styled.div`
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #222222;
`;

const Caution = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(55, 56, 60, 0.61);
  background-color: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
`;

const CautionText = styled.div`
  width: 100%;
`;
const NoticeBox = styled.div`
  padding: 10px 16px;
  background: rgba(112, 115, 124, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const NoticeWrapper = styled.div`
  width: 100%;
`;
