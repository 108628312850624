import styled from 'styled-components';
import colors from '../../../../color';

export const SectionContainer = styled.div`
  width: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: white;
  position: relative;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-family: Pretendard-Bold;
  font-size: 18px;
  flex: 1;
  justify-content: flex-start;
  color: #222222;
`;
export const DateBox = styled.span`
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-family: Pretendard-Medium;
  font-size: 12px;
  color: ${colors.labelAlternative};
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TopText = styled.div`
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNeutral};
`;
export const TopTextStrong = styled.span`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: ${colors.labelNormal};
`;
export const BottomText = styled.div`
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: ${colors.labelNormal};
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
export const BottomTextStrong = styled.span`
  font-family: Pretendard-Bold;
  font-size: 20px;
  color: #ec4f4f;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flaex-end;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 13px;
  color: ${colors.labelAlternative};
`;

export const Circle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #1e9b6b;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  padding-top: 40px;
`;

export const GrayBar = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 30px;
  background-color: rgba(112, 115, 124, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const GreenBar = styled.div`
  width: 40%;
  height: 8px;
  border-radius: 30px;
  background-color: #1e9b6b;
`;

export const PercentageBox = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 13px;
  color: ${colors.labelAlternative};
  position: relative;
  margin-bottom: 16px;
`;

export const TooltipBox = styled.div`
  width: 280px;
  position: absolute;
  top: 58px;
  left: 16px;
  z-index: 100;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  background-color: #ffffff;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

export const TooltipText = styled.div`
  flex: 1;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: '#222222';
`;

export const PercentageItem = styled.span<{ position: number }>`
  position: absolute;
  left: ${({ position }) => position}%;
  transform: translateX(-50%);
  top: 8%;
`;

export const NoServeData = styled.div`
  padding: 16px 0px;
  color: rgba(55, 56, 60, 0.61);
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
`;
