import styled from 'styled-components';
import colors from '../../../color';
import { useEffect, useState } from 'react';
import { set } from 'lodash';

interface FilterModalProps {
  selected?: boolean;
  smallDevice?: boolean;
}

const SaleItemFilter = ({
  setFilterModalOn,
  smallDevice,
  deviceHeight,
  setSaleItemOption,
  setShowModal,
}: any) => {
  const itemType = ['토지', '주택'];
  const saleStatus = ['공개', '비공개', '거래완료'];

  const [tempFilterOption, setTempFilterOption] = useState({
    itemType: ['토지', '주택'],
    saleStatus: ['공개', '비공개', '거래완료'],
  });
  //필터가 켜졌을 때 스크롤 비활성화
  useEffect(() => {
    // 스크롤 비활성화
    document.body.style.overflow = 'hidden';

    // mychonContainer 요소 선택 후 스크롤 비활성화
    const container = document.getElementById('mychonContainer');
    if (container) {
      container.style.overflow = 'hidden';
    }

    // 컴포넌트 언마운트 시 스크롤 복원
    return () => {
      document.body.style.overflow = '';
      if (container) {
        container.style.overflow = '';
      }
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('filterOption')) {
      const existingFilterOption = JSON.parse(
        localStorage.getItem('filterOption') || '{}',
      );
      setTempFilterOption(existingFilterOption.sale);
    }
  }, []);

  const handleReset = () => {
    setSaleItemOption({
      itemType: ['토지', '주택'],
      saleStatus: ['공개', '비공개', '거래완료'],
    });
    setTempFilterOption({
      itemType: ['토지', '주택'],
      saleStatus: ['공개', '비공개', '거래완료'],
    });
  };

  const handleApplyClick = () => {
    // 기존 filterOption 가져오기
    const existingFilterOption = JSON.parse(
      localStorage.getItem('filterOption') || '{}',
    );

    // 상태 업데이트
    setSaleItemOption(tempFilterOption);
    setFilterModalOn(false);
    setShowModal({ status: true, text: '필터가 적용되었습니다.' });
  };

  const handleSelect = (option: string, key: keyof typeof tempFilterOption) => {
    setTempFilterOption((prev) => {
      const filtered = prev[key].filter((item: string) => item !== '전체');

      if (filtered.includes(option)) {
        return {
          ...prev,
          [key]:
            filtered.filter((item: string) => item !== option).length === 0
              ? [option]
              : filtered.filter((item: string) => item !== option),
        };
      } else {
        return { ...prev, [key]: [...filtered, option] };
      }
    });
  };

  return (
    <ModalContainer>
      <ModalInner smallDevice={smallDevice}>
        <ModalTitle>
          <img
            src="/asset/images/close/close-gray.svg"
            alt="Close"
            onClick={() => {
              setFilterModalOn(false);
            }}
            style={{ cursor: 'pointer' }}
          />
        </ModalTitle>
        <SectionBox
          style={{
            height: `${deviceHeight - 62 - 84}px`,
          }}
        >
          <Section>
            <SectionLabel>매물유형</SectionLabel>
            <OptionBox>
              {itemType.map((option: any, index: any) => (
                <OptionItem
                  key={index}
                  selected={
                    (tempFilterOption &&
                      tempFilterOption?.itemType?.includes(option)) ??
                    false // itemType이 없으면 false 반환
                  }
                  onClick={() => {
                    handleSelect(option, 'itemType');
                  }}
                  style={{ flex: 1 }}
                >
                  {option}
                </OptionItem>
              ))}
            </OptionBox>
          </Section>
          <Section>
            <SectionLabel>매물상태</SectionLabel>
            <OptionBox>
              {saleStatus.map((option: any, index: any) => (
                <OptionItem
                  key={index}
                  selected={
                    tempFilterOption &&
                    tempFilterOption?.saleStatus.includes(option)
                  }
                  onClick={() => {
                    handleSelect(option, 'saleStatus');
                  }}
                  style={{ flex: 1 }}
                >
                  {option}
                </OptionItem>
              ))}
            </OptionBox>
          </Section>
        </SectionBox>
        <ApplyBtnBox>
          <ResetBtn onClick={handleReset}>
            <img src="/asset/images/icon/reset.svg" alt="" />
            <ResetBtnText>초기화</ResetBtnText>
          </ResetBtn>
          <ApplyBtn
            onClick={() => {
              handleApplyClick();
            }}
          >
            적용
          </ApplyBtn>
        </ApplyBtnBox>
      </ModalInner>
    </ModalContainer>
  );
};
export default SaleItemFilter;

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.52);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

const ModalInner = styled.div<FilterModalProps>`
  width: 100%;
  height: ${({ smallDevice }) => (smallDevice ? '100%' : 'auto')};
  background-color: white;
  border-radius: 20px 20px 0 0;
  position: relative;
`;

const ModalTitle = styled.div`
  width: 100%;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  line-height: 24px;
  color: ${colors.labelNeutral};
  height: 62px;
`;

const SectionBox = styled.div<FilterModalProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${colors.backgroundNeutral};
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 82px;
`;
const Section = styled.div`
  flex: 1;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.white};
`;

const SectionLabel = styled.div`
  width: 100%;
  font-family: 'Pretendard-Bold';
  font-size: 16px;
  color: black;
  display: flex;
  justify-content: space-between;
`;
const OptionBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 8px;
`;
const OptionItem = styled.span<FilterModalProps>`
  width: 100%;
  height: 44px;
  padding: 10px 0;
  font-family: 'Pretendard-Medium';
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ selected }) => (selected ? colors.primary500 : colors.lineNeutral)};
  color: ${({ selected }) =>
    selected ? colors.primary500 : colors.labelAlternative};
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
`;

const ApplyBtnBox = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.lineNormal};
`;

const ResetBtn = styled.div`
  padding: 7px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${colors.lineNormal};
  cursor: pointer;
`;

const ResetBtnText = styled.span`
  font-family: 'Pretendard-Medium';
  font-size: 10px;
  color: ${colors.labelAlternative};
`;
const ApplyBtn = styled.span`
  flex: 1;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Pretendard-Bold';
  font-size: 16px;
  color: ${colors.white};
  background-color: ${colors.primary500};
  cursor: pointer;
`;
