import styled from 'styled-components';
import { Button } from '../../delete/components/Button';

const SaleCompleteModal = ({ setAlertYN, setItemStatusChange }: any) => {
  return (
    <div
      id="post-meta-col"
      className="flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="bg-FFFFFF padding-24-16 border-radius-16 gap-12 w-312 flex-center flex-column">
        <div className="flex-column w-100per">
          <PageTop>
            <img
              style={{ width: '24px', height: '24px' }}
              src="/asset/images/close/close-darkgray.svg"
              alt=""
              onClick={() => {
                setAlertYN(false);
                setItemStatusChange(false);
              }}
            />
          </PageTop>
          <div
            className="flex-column w-100per h-100per"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: '16px',
              paddingBottom: '24px',
            }}
          >
            <DeleteTextBox>
              <div>거래완료를 선택할 경우</div>
              <div>수정이 불가하며, 동일 매물 거래를 위해서는</div>
              <div>삭제 후 재등록이 필요합니다</div>
            </DeleteTextBox>
          </div>
          <div className="p flex w-100per gap-8">
            <Button
              type="deleteModal"
              description="취소"
              onClick={() => {
                setAlertYN(false);
                setItemStatusChange(false);
              }}
            />
            <Button
              on={true}
              description="거래완료"
              onClick={() => {
                setAlertYN(false);
                setItemStatusChange(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SaleCompleteModal;

const DeleteText = styled.div`
  color: rgba(55, 56, 60, 0.61);
  font-size: 14px;
  font-family: Pretendard-medium;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
`;

const PageTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const DeleteTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // gap: 8px;
  width: 100%;

  color: black;
  font-size: 16px;
  font-family: Pretendard-medium;
  font-weight: 700;
  line-height: 24px;
  word-wrap: break-word;
  span {
    color: rgba(30, 155, 107, 1);
  }
`;
