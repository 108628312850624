import styled from 'styled-components';
interface ButtonProps {
  active: boolean;
}

const Container = styled.div`
  max-width: 720px;
  height: 100vh;
  position: relative;
  background-color: #fff;
  overflow-y: auto;
  box-sizing: content-box;
`;
const PageTop = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;
`;
const ContentBox = styled.div`
  width: 100%;
  padding: 32px 16px 166px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SectionLabel = styled.div`
  font-family: Pretendard-Bold;
  font-size: 16px;
  color: #171e26;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const MustGreen = styled.span`
  color: #09ce7d;
`;
const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CheckBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #222;
  padding: 6px 0;
  cursor: pointer;
`;

const SectionButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const SectionButton = styled.div<ButtonProps>`
  flex: 1;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${({ active }) =>
      active ? 'rgba(30, 155, 107, 1)' : 'rgba(112, 115, 124, 0.16)'};
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${({ active }) =>
    active ? 'rgba(30, 155, 107, 1)' : 'rgba(55, 56, 60, 0.61)'};
  cursor: pointer;
`;
const AddressInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SearchAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const SearchButton = styled.button`
  width: 87px;
  height: 50px;
  border-radius: 10px;
  background-color: #000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;
const Input = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 10px;
  padding: 12px 14px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  border: 1px solid rgba(112, 115, 124, 0.16);
`;
const BlueInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #e8f2ff;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
`;
const PhotoUploadInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;
const TextRed = styled.div`
  color: rgba(236, 79, 79, 1);
`;
const TextAreaBox = styled.textarea`
  width: 100%;
  padding: 16px 14px;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 16px;
  resize: none;
  outline: none;

  &::placeholder {
    color: rgba(55, 56, 60, 0.28);
    white-space: pre-line;
  }
`;

const CountBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: rgba(55, 56, 60, 0.61);
`;
const InputBox = styled.div`
  width: 100%;
  height: 50px;
  padding: 16px 14px;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NonBorderInput = styled.input`
  flex: 1;
  border: none;
  font-family: Pretendard-Medium;
  font-size: 16px;
  text-align: right;
  padding-right: 8px;

  &::placeholder {
    color: rgba(55, 56, 60, 0.28);
  }
`;
const AddAmountBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const AddAmountButton = styled.span`
  flex: 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: rgba(55, 56, 60, 0.61);
  cursor: pointer;
`;
const WithLabelInputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: Pretendard-Regular;
  font-size: 14px;
  color: #616161;
`;

const ImagesBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
`;
const SeletedImage = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 10px;
  position: relative;
`;
const RemoveButton = styled.img`
  position: absolute;
  top: -8px;
  right: -10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const UploadButton = styled.div`
  display: flex;
  width: 92px;
  height: 92px;
  padding: 16px 14px 14px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  background-color: #f7f7f8;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #616161;
  cursor: pointer;
`;

const RedInfoBox = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  border-radius: 8px;
  background: #ffeded;
`;
const RedInfoBoxTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #222;
`;
const MoreAddressButton = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #222;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: white;
  margin-top: 8px;
  cursor: pointer;
`;
const RemoveAddressButton = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(112, 115, 124, 0.16);
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: #222;
`;
const AddressType = styled.div`
  width: 100%;
  font-family: Pretendard-Regular;
  font-size: 14px;
  color: #616161;
`;

const AddressNoticeBox = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background-color: #f7f8fc;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: #616161;
`;
const AddressNoticeText = styled.span`
  font-family: Pretendard-Bold;
  font-size: 14px;
  color: #09ce7d;
`;

export const BlueTooltip = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #0073f0;
  height: 44px;
  position: relative;
  overflow: visible;
  font-family: 'Pretendard-Medium';
  font-size: 14px;
  color: #fff;
  box-shadow:
    0px 2px 8px 0px rgba(0, 0, 0, 0.12),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08);

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #0073f0;
  }
`;

export const TextYellow = styled.span`
  color: #ffffa5;
`;

const ButtonBox = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  padding: 20px 16px 24px;
  max-width: inherit;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 16.49%);
`;
const GreenBox = styled.button`
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: none;
  font-family: Pretendard-Bold;
  font-size: 18px;
  cursor: pointer;
`;

export const s = {
  Container,
  PageTop,
  ContentBox,
  Section,
  SectionLabel,
  MustGreen,
  SectionContent,
  SectionButtonBox,
  SectionButton,
  AddressInputBox,
  SearchAddress,
  SearchButton,
  Input,
  BlueInfoBox,
  PhotoUploadInfo,
  TextRed,
  TextAreaBox,
  CountBox,
  InputBox,
  NonBorderInput,
  AddAmountBox,
  AddAmountButton,
  WithLabelInputBox,
  ImagesBox,
  SeletedImage,
  RemoveButton,
  UploadButton,
  CheckBox,
  RedInfoBox,
  RedInfoBoxTexts,
  MoreAddressButton,
  RemoveAddressButton,
  AddressType,
  AddressNoticeBox,
  AddressNoticeText,
  BlueTooltip,
  TextYellow,
  ButtonBox,
  GreenBox,
};
