import styled from 'styled-components';
import colors from '../../color';

interface MyChoneProps {
  active?: boolean;
}

export const Container = styled.div.attrs({
  id: 'mychonContainer', // HTML의 id 속성 사용
})`
  max-width: 720px;
  height: 100vh;
  position: relative;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  overflow-y: auto;
`;
export const PageTitle = styled.div`
  width: 100%;
  padding: 15px 16px;
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: ${colors.labelNormal};
`;
export const MyInfoBox = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ProfileBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: Pretendard-Medium;
  font-size: 16px;
  color: ${colors.labelNormal};
`;

export const Name = styled.span`
  font-family: Pretendard-Bold;
  color: #000;
`;
export const Img = styled.img`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: rgba(112, 115, 124, 0.16);
`;
export const NoImg = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: rgba(112, 115, 124, 0.16);
  border 1px solid rgba(225, 226, 228, 1); 
`;

export const MyInfoButton = styled.span`
  width: 62px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNeutral};
  border-radius: 4px;
  background-color: rgba(112, 115, 124, 0.08);
  cursor: pointer;
`;

export const PointBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.lineNeutral};
`;

export const MyPointInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelAlternative};
`;
export const PointText = styled.span`
  font-family: Pretendard-Bold;
  font-size: 18px;
  color: #222;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
export const PointButtonBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const PointButton = styled.div`
  flex: 1;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Pretendard-Medium;
  font-size: 14px;
  color: ${colors.labelNormal};
  border-radius: 4px;
  background-color: #f5f5f5;
  cursor: pointer;
`;

export const TabMenuBox = styled.div`
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #e1e2e4;
`;
export const TabMenuInner = styled.div`
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
`;
export const TabMenu = styled.div<MyChoneProps>`
  flex: 1;
  border-bottom: 2px solid ${({ active }) => (active ? '#222' : 'transparent')};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ active }) =>
    active ? 'Pretendard-Bold' : 'Pretendard-Medium'};
  font-size: 16px;
  color: ${({ active }) => (active ? '#222' : '#989ba2')};
  cursor: pointer;
`;
