import { useEffect, useState } from 'react';
import { s } from './SaleItemEdit.styles';
import SnackBar from './SnackBar';
import { useNavigate } from 'react-router-dom';
import CancelModal from './CancelModal';
import Sales from '../../../../service/Sales';
import InfoConfirmModal from './InfoConfirmModal';
import CompletionModal from '../CompletionModal';
import { UseEditSaleItemContext } from '../../../../contexts/EditSaleItemContext';

const Amount = [
  { label: '+1억원', value: 100000000 },
  { label: '+천만원', value: 10000000 },
  { label: '+백만원', value: 1000000 },
  { label: '+십만원', value: 100000 },
];

const EditProperty = () => {
  const [isPriceFocused, setIsPriceFocused] = useState(false);
  const [isAreaFocused, setIsAreaFocused] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCompletionModal, setOpenCompletionModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  const {
    registrant,
    setRegistrant,
    isHolder,
    setIsHolder,
    type,
    setType,
    addressList,
    setAddressList,
    isAddressPublic,
    setIsAddressPublic,
    saleItemImages,
    setSaleItemImages,
    saleItemImagesURLs,
    setSaleItemImagesURLs,
    explanation,
    setExplanation,
    detailExplanation,
    setDetailExplanation,
    area,
    setArea,
    expectedAmount,
    setExpectedAmount,
    name,
    setName,
    phone,
    setPhone,
    isView,
    setIsView,
    company,
    setCompany,
    officePostCode,
    setOfficePostCode,
    officeAddress,
    setOfficeAddress,
    officeAddressDetail,
    setOfficeAddressDetail,
    owner,
    setOwner,
    brokerageNumber,
    setBrokerageNumber,
    businessNumber,
    setBusinessNumber,
    imgList,
    setImgList,
    sIdx,
    setSIdx,
    deleteImg,
    setDeleteImg,
  } = UseEditSaleItemContext();

  useEffect(() => {
    const path = window.location.pathname;
    const pathArr = path.split('/');
    setSIdx(pathArr[pathArr.length - 1]);
  }, []);

  const getDetail = async (sidx: string) => {
    try {
      const res = await Sales.salesItemDetail(sidx);
      setRegistrant(res.result.owner_type);
      setIsHolder(res.result.owner_type === 'A' ? 'Y' : 'N');
      setType(res.result.type);
      const addressInfo = res.result.address_info;
      const formattedAddressInfo = addressInfo.map(
        ({ post_code, addr_detail, address }: any) => ({
          postCode: post_code,
          detail: addr_detail,
          jibunAddress: address,
        }),
      );
      setAddressList(formattedAddressInfo);
      setIsAddressPublic(res.result.addr_yn);
      setImgList(res.result.img === null ? [] : res.result.img.split(','));
      setExplanation(res.result.desc_title);
      setDetailExplanation(res.result.desc_detail);
      setArea(res.result.area);
      setExpectedAmount(res.result.price);
      setName(res.result.name);
      setPhone(res.result.phone);
      setCompany(res.result.b_company);
      setOfficePostCode(res.result.b_post_code);
      setOfficeAddress(res.result.b_address);
      setOfficeAddressDetail(res.result.b_detail);
      setOwner(res.result.b_name);
      setBrokerageNumber(res.result.b_id);
      setBusinessNumber(res.result.b_biz_id);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (sIdx !== '') {
      getDetail(sIdx);
    }
  }, [sIdx]);

  const handleBrokerageOfficeAddress = () => {
    new window.daum.Postcode({
      oncomplete: (data) => {
        const postCode = data.zonecode;
        const address = data.jibunAddress;

        setOfficePostCode(postCode);
        setOfficeAddress(address);
      },
    }).open();
  };

  const handleAddressSearch = (index: number) => {
    new window.daum.Postcode({
      oncomplete: (data) => {
        const updatedAddress = {
          postCode: data.zonecode,
          jibunAddress: data.jibunAddress,
          detail: '',
        };

        setAddressList((prevList) => {
          const newList = [...prevList];
          if (index === 0) {
            newList[0] = updatedAddress;
          } else {
            newList[newList.length - 1] = updatedAddress;
          }
          return newList;
        });
      },
    }).open();
  };

  const handleRemoveAddress = (index: number) => {
    const updatedList = addressList.filter((_, i) => i !== index);
    setAddressList(updatedList);
    getArea({ addressInfo: updatedList });
    setIsDelete(true);

    const timeout = setTimeout(() => {
      setIsDelete(false);
    }, 2000);

    return () => clearTimeout(timeout);
  };
  const handleAddAddress = () => {
    setAddressList((prev) => [
      ...prev,
      { postCode: '', jibunAddress: '', detail: '' },
    ]);
  };

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<File[]>>,
    urlSetter: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
      const MAX_FILES = 5 - imgList.length;

      const invalidFiles: string[] = [];
      const validFiles = filesArray.filter((file) => {
        const isValidSize = file.size <= MAX_FILE_SIZE;
        const isValidType = ['image/jpeg', 'image/png'].includes(file.type);

        if (!isValidType) {
          invalidFiles.push(`jpg, png 파일만 업로드 가능합니다.`);
        } else if (!isValidSize) {
          invalidFiles.push(`${file.name}: 파일 크기가 10MB를 초과합니다.`);
        }

        return isValidSize && isValidType;
      });

      if (invalidFiles.length > 0) {
        alert(invalidFiles.join('\n'));
      }

      setter((prevFiles) => {
        const newFiles = [...prevFiles, ...validFiles].slice(0, MAX_FILES);
        if (newFiles.length > MAX_FILES) {
          alert(`최대 ${MAX_FILES}개 파일만 업로드할 수 있습니다.`);
        }
        return newFiles;
      });

      urlSetter((prevURLs) => {
        const newURLs = [
          ...prevURLs,
          ...validFiles.map((file) => URL.createObjectURL(file)),
        ].slice(0, MAX_FILES);
        return newURLs;
      });
    }
  };

  const handleRemoveFile = (
    index: number,
    setter: React.Dispatch<React.SetStateAction<File[]>>,
    urlSetter: React.Dispatch<React.SetStateAction<string[]>>,
  ) => {
    setter((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
    urlSetter((prevURLs) => {
      const updatedURLs = [...prevURLs];
      updatedURLs.splice(index, 1);
      return updatedURLs;
    });
  };

  useEffect(() => {
    return () => {
      saleItemImagesURLs.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [saleItemImagesURLs]);

  useEffect(() => {
    if (!isView && addressList[0].jibunAddress && addressList[0].detail) {
      setShowTooltip(true);
      const timeout = setTimeout(() => {
        setShowTooltip(false);
        setIsView(true);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [addressList[0].jibunAddress, addressList[0].detail]);

  const checkButtonStatus = () => {
    if (registrant === 'A' || registrant === 'B') {
      if (
        type !== '' &&
        addressList[0].jibunAddress !== '' &&
        addressList[0].detail !== '' &&
        isAddressPublic !== '' &&
        (imgList.length > 0 || saleItemImages.length > 0) &&
        explanation !== '' &&
        area !== '' &&
        expectedAmount !== '' &&
        name !== '' &&
        phone !== ''
      ) {
        setIsButtonActive(true);
      } else {
        setIsButtonActive(false);
      }
    } else if (registrant === 'C') {
      if (
        company !== '' &&
        officeAddress !== '' &&
        officeAddressDetail !== '' &&
        owner !== '' &&
        brokerageNumber !== '' &&
        businessNumber !== '' &&
        type !== '' &&
        addressList[0].jibunAddress !== '' &&
        addressList[0].detail !== '' &&
        (imgList.length > 0 || saleItemImages.length > 0) &&
        explanation !== '' &&
        area !== '' &&
        expectedAmount !== '' &&
        name !== '' &&
        phone !== '' &&
        isAddressPublic !== ''
      ) {
        setIsButtonActive(true);
      } else {
        setIsButtonActive(false);
      }
    }
  };

  useEffect(() => {
    checkButtonStatus();
  }, [
    isAddressPublic,
    registrant,
    isHolder,
    type,
    addressList,
    saleItemImages,
    explanation,
    area,
    expectedAmount,
    name,
    phone,
    company,
    officeAddress,
    officeAddressDetail,
    owner,
    brokerageNumber,
    businessNumber,
    imgList,
  ]);

  const handleButtonClick = () => {
    if (isButtonActive) {
      setOpenConfirmModal(true);
    } else {
      alert('필수 입력값을 전부 입력해 주세요');
    }
  };

  const handleReset = () => {
    setRegistrant('');
    setIsHolder('N');
    setType('');
    setAddressList([
      {
        postCode: '',
        jibunAddress: '',
        detail: '',
      },
    ]);
    setIsAddressPublic('');
    setSaleItemImages([]);
    setSaleItemImagesURLs([]);
    setExplanation('');
    setDetailExplanation('');
    setArea('');
    setExpectedAmount('');
    setName('');
    setPhone('');
    setIsView(false);
    setCompany('');
    setOfficePostCode('');
    setOfficeAddress('');
    setOfficeAddressDetail('');
    setOwner('');
    setBrokerageNumber('');
    setBusinessNumber('');
  };

  const handleBackButtonClick = () => {
    if (
      company !== '' ||
      officeAddress !== '' ||
      officeAddressDetail !== '' ||
      owner !== '' ||
      brokerageNumber !== '' ||
      businessNumber !== '' ||
      type !== '' ||
      addressList[0].jibunAddress !== '' ||
      saleItemImages.length > 0 ||
      explanation !== '' ||
      area !== '' ||
      expectedAmount !== '' ||
      name !== '' ||
      phone !== ''
    ) {
      setShowCancelModal(true);
      handleReset();
    } else {
      navigate(-1);
    }
  };

  const getArea = async (data: any) => {
    try {
      const res = await Sales.changeArea(data);
      setArea(res.data.result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <s.Container>
      {openConfirmModal && (
        <InfoConfirmModal
          setOpenConfirmModal={setOpenConfirmModal}
          setOpenCompletionModal={setOpenCompletionModal}
        />
      )}
      {openCompletionModal && (
        <CompletionModal setOpenCompletionModal={setOpenCompletionModal} />
      )}
      {showCancelModal && (
        <CancelModal setShowCancelModal={setShowCancelModal} />
      )}
      {isDelete && <SnackBar />}
      <s.PageTop>
        <img
          src="/asset/images/arrow/arrow-tail-left-black.svg"
          alt=""
          onClick={handleBackButtonClick}
          style={{ cursor: 'pointer' }}
        />
        <span>매물 등록하기</span>
      </s.PageTop>
      <s.ContentBox>
        {/* 매물등록인 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 등록인</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.SectionContent>
            <s.SectionButtonBox>
              <s.SectionButton
                active={registrant === 'B' || registrant === 'A'}
                onClick={() => {
                  setRegistrant('B');
                }}
              >
                개인
              </s.SectionButton>
              <s.SectionButton
                active={registrant === 'C'}
                onClick={() => {
                  setRegistrant('C');
                }}
              >
                부동산
              </s.SectionButton>
            </s.SectionButtonBox>
            {registrant === '부동산' && (
              <s.AddressNoticeBox>
                <s.AddressNoticeText>안내</s.AddressNoticeText>
                <span>공인중개사 정보 제공이 필요해요</span>
              </s.AddressNoticeBox>
            )}
          </s.SectionContent>
          {registrant === 'B' && (
            <s.CheckBox
              onClick={() => {
                setIsHolder(isHolder === 'N' ? 'Y' : 'N');
              }}
            >
              {isHolder === 'N' ? (
                <img
                  src="/asset/images/check/check_off.svg"
                  alt=""
                  style={{ width: '24px', height: '24px' }}
                />
              ) : (
                <img
                  src="/asset/images/check/check_on.svg"
                  alt=""
                  style={{ width: '24px', height: '24px' }}
                />
              )}

              <span>소유주라면 체크해주세요</span>
            </s.CheckBox>
          )}
        </s.Section>
        {/* 공인중개사 정보 입력 */}
        {registrant === 'C' && (
          <s.Section>
            <s.SectionLabel>
              <span>공인중개사 정보 입력</span> <s.MustGreen>*필수</s.MustGreen>
            </s.SectionLabel>
            <s.WithLabelInputBox>
              <div>상호명</div>
              <s.Input
                type="text"
                placeholder="홍길동"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </s.WithLabelInputBox>
            <s.WithLabelInputBox>
              <div>사무실 주소</div>
              <s.AddressInputBox>
                <s.SearchAddress>
                  <s.Input
                    type="text"
                    placeholder="주소 찾기로 입력해 주세요"
                    disabled
                    style={{ flex: 1 }}
                    value={officePostCode}
                  />
                  <s.SearchButton onClick={handleBrokerageOfficeAddress}>
                    주소찾기
                  </s.SearchButton>
                </s.SearchAddress>
                <s.Input
                  type="text"
                  placeholder="주소 찾기로 입력해 주세요"
                  disabled
                  value={officeAddress}
                />
                <s.Input
                  type="text"
                  placeholder="상세 주소를 입력해 주세요"
                  value={officeAddressDetail}
                  onChange={(e) => {
                    setOfficeAddressDetail(e.target.value);
                  }}
                />
              </s.AddressInputBox>
            </s.WithLabelInputBox>
            <s.WithLabelInputBox>
              <div>대표자 이름</div>
              <s.Input
                type="text"
                placeholder="홍길동"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
              />
            </s.WithLabelInputBox>
            <s.WithLabelInputBox>
              <div>부동산 중개업 등록번호</div>
              <s.Input
                type="text"
                placeholder="- 를 포함해서 입력해주세요"
                value={brokerageNumber}
                onChange={(e) => setBrokerageNumber(e.target.value)}
              />
            </s.WithLabelInputBox>
            <s.WithLabelInputBox>
              <div>대표 연락처</div>
              <s.Input
                type="text"
                placeholder="숫자만 입력해 주세요"
                value={businessNumber}
                onChange={(e) => setBusinessNumber(e.target.value)}
              />
            </s.WithLabelInputBox>
          </s.Section>
        )}
        {/* 매물 유형 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 유형</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.SectionContent>
            <s.SectionButtonBox>
              <s.SectionButton
                active={type === 'L'}
                onClick={() => {
                  setType('L');
                }}
              >
                토지
              </s.SectionButton>
              <s.SectionButton
                active={type === 'H'}
                onClick={() => {
                  setType('H');
                }}
              >
                주택
              </s.SectionButton>
            </s.SectionButtonBox>
          </s.SectionContent>
        </s.Section>
        {/* 매물 상세 주소 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 상세 주소</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.SectionContent>
            {addressList.map((item, index) => (
              <s.AddressInputBox key={index}>
                <s.AddressType>
                  {index === 0 ? '대표 주소지' : `주소지${index}`}{' '}
                </s.AddressType>
                <s.SearchAddress>
                  <s.Input
                    type="text"
                    placeholder="주소 찾기로 입력해 주세요"
                    value={item.postCode}
                    disabled
                    style={{ flex: 1 }}
                  />
                  <s.SearchButton onClick={() => handleAddressSearch(index)}>
                    주소찾기
                  </s.SearchButton>
                </s.SearchAddress>
                <s.Input
                  type="text"
                  placeholder="주소 찾기로 입력해 주세요"
                  value={item.jibunAddress}
                  disabled
                />
                <s.Input
                  type="text"
                  placeholder="상세 주소를 입력해 주세요"
                  value={item.detail}
                  onChange={(e) => {
                    const updatedList = [...addressList];
                    updatedList[index].detail = e.target.value;
                    setAddressList(updatedList);
                  }}
                  onBlur={(e) => {
                    const updatedList = [...addressList];
                    updatedList[index].detail = e.target.value;
                    setAddressList(updatedList);
                    getArea({ addressInfo: updatedList });
                  }}
                />
                {index === 0 && (
                  <s.BlueInfoBox>
                    <img src="/asset/images/icon/blue-info.svg" alt="" />
                    <span>지도에 노출되는 대표 주소지 입니다</span>
                  </s.BlueInfoBox>
                )}
                {index !== 0 && (
                  <s.RemoveAddressButton
                    onClick={() => {
                      handleRemoveAddress(index);
                    }}
                  >
                    - 주소지 삭제
                  </s.RemoveAddressButton>
                )}
              </s.AddressInputBox>
            ))}
            <div style={{ width: '100%', position: 'relative' }}>
              {addressList[0].jibunAddress && addressList[0].detail && (
                <s.MoreAddressButton onClick={handleAddAddress}>
                  <span> + 주소지 추가</span>
                </s.MoreAddressButton>
              )}
              {showTooltip && !isView && (
                <div
                  style={{
                    width: '100%',
                    position: 'absolute',
                    bottom: -50,
                  }}
                >
                  <s.BlueTooltip>
                    <div>
                      등록하실 필지가 여러개일 때,
                      <s.TextYellow> 모두 등록해주세요</s.TextYellow>
                    </div>
                    <img
                      src="/asset/images/close/close-white.svg"
                      alt=""
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setShowTooltip(false);
                      }}
                    />
                  </s.BlueTooltip>
                </div>
              )}
            </div>
          </s.SectionContent>
          {addressList[0].jibunAddress && addressList[0].detail && (
            <s.WithLabelInputBox>
              <div>주소지 공개여부</div>
              <s.SectionButtonBox>
                <s.SectionButton
                  active={isAddressPublic === 'Y'}
                  onClick={() => {
                    setIsAddressPublic('Y');
                  }}
                >
                  전체 공개
                </s.SectionButton>
                <s.SectionButton
                  active={isAddressPublic === 'N'}
                  onClick={() => {
                    setIsAddressPublic('N');
                  }}
                >
                  일부 공개
                </s.SectionButton>
              </s.SectionButtonBox>
              <s.AddressNoticeBox>
                <s.AddressNoticeText>안내</s.AddressNoticeText>
                <span>일부 공개 시 상세 지번은 제외하여 노출됩니다</span>
              </s.AddressNoticeBox>
            </s.WithLabelInputBox>
          )}
        </s.Section>
        {/* 매물 사진 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 사진</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.ImagesBox>
            {imgList.map((img, index) => (
              <s.SeletedImage key={index}>
                <img
                  src={`${process.env.REACT_APP_HOST}img/${img}`}
                  alt="Preview"
                  style={{
                    width: '92px',
                    height: '92px',
                    borderRadius: '10px',
                  }}
                />
                <s.RemoveButton
                  src="/asset/images/close/Close28Ring.svg"
                  alt=""
                  onClick={() => {
                    setImgList((prevList) =>
                      prevList.filter((_, i) => i !== index),
                    );
                    setDeleteImg([...deleteImg, img]);
                  }}
                />
              </s.SeletedImage>
            ))}
            {saleItemImagesURLs.map((url, index) => (
              <s.SeletedImage key={index}>
                <img
                  src={url}
                  alt="Preview"
                  style={{
                    width: '92px',
                    height: '92px',
                    borderRadius: '10px',
                  }}
                />
                <s.RemoveButton
                  src="/asset/images/close/Close28Ring.svg"
                  alt=""
                  onClick={() =>
                    handleRemoveFile(
                      index,
                      setSaleItemImages,
                      setSaleItemImagesURLs,
                    )
                  }
                />
              </s.SeletedImage>
            ))}
            {saleItemImages.length + imgList.length < 5 && (
              <label htmlFor="file">
                <s.UploadButton>
                  <img src="/asset/images/icon/add-photo.svg" alt="" />
                  <div>{saleItemImages.length + imgList.length}/5</div>
                </s.UploadButton>
              </label>
            )}
            <input
              id="file"
              type="file"
              multiple
              onChange={(e) =>
                handleImageChange(e, setSaleItemImages, setSaleItemImagesURLs)
              }
              style={{ display: 'none' }}
            />
          </s.ImagesBox>

          <s.PhotoUploadInfo>
            <div>*개별 10MB 이하, 총 50MB 이하로 등록가능합니다</div>
            <div>*jpg, png만 등록가능합니다</div>
            <s.TextRed>
              *매물과 무관한 사진은 통보없이 삭제될 수 있습니다
            </s.TextRed>
          </s.PhotoUploadInfo>
        </s.Section>
        {/* 매물 간단 설명 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 간단 설명</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.TextAreaBox
            maxLength={30}
            placeholder={`매물 관련 간략한 설명을 입력해 주세요\n(최대 30자)`}
            value={explanation}
            onChange={(e) => {
              setExplanation(e.target.value);
            }}
          />
          <s.CountBox>
            <span
              style={{
                color:
                  explanation.length > 1 ? '#ec4f4f' : 'rgba(55, 56, 60, 0.61)',
              }}
            >
              {explanation.length}
            </span>
            /30
          </s.CountBox>
        </s.Section>
        {/* 매물 상세 설명 */}
        <s.Section>
          <s.SectionLabel>
            <span>매물 상세 설명</span>{' '}
            <span
              style={{
                fontSize: '14px',
                color: 'rgba(55, 56, 60, 0.61)',
              }}
            >
              (선택 사항)
            </span>
          </s.SectionLabel>
          <s.TextAreaBox
            placeholder="매물 관련 상세 설명을 입력해 주세요"
            style={{ height: '200px', overflow: 'auto' }}
            value={detailExplanation}
            onChange={(e) => {
              setDetailExplanation(e.target.value);
            }}
          />
        </s.Section>
        {/* 매매 면적 */}
        <s.Section>
          <s.SectionLabel>
            <span>매매 면적</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.InputBox>
            <s.NonBorderInput
              type="text"
              placeholder="면적을 입력해 주세요"
              value={
                isAreaFocused
                  ? area
                  : area === ''
                    ? area
                    : Number(area).toLocaleString()
              }
              onChange={(e) => setArea(e.target.value.replace(/[^0-9]/g, ''))}
              onFocus={() => setIsAreaFocused(true)}
              onBlur={() => setIsAreaFocused(false)}
            />
            <span>m²</span>
          </s.InputBox>
        </s.Section>
        {/* 희망 매매가 */}
        <s.Section>
          <s.SectionLabel>
            <span>희망 매매가</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.SectionContent>
            <s.InputBox>
              <s.NonBorderInput
                type="text"
                placeholder="희망 매매가를 입력해 주세요"
                value={
                  isPriceFocused
                    ? expectedAmount
                    : expectedAmount === ''
                      ? expectedAmount
                      : Number(expectedAmount).toLocaleString()
                }
                onChange={(e) =>
                  setExpectedAmount(e.target.value.replace(/[^0-9]/g, ''))
                }
                onFocus={() => setIsPriceFocused(true)}
                onBlur={() => setIsPriceFocused(false)}
              />
              <span>원</span>
            </s.InputBox>
            <s.AddAmountBox>
              {Amount.map((item, index) => (
                <s.AddAmountButton
                  key={index}
                  onClick={() => {
                    setExpectedAmount((prev) => {
                      if (prev === '') {
                        return item.value.toString();
                      }
                      return (parseInt(prev) + item.value).toString();
                    });
                  }}
                >
                  {item.label}
                </s.AddAmountButton>
              ))}
            </s.AddAmountBox>
            <s.RedInfoBox>
              <img src="/asset/images/icon/red-warn.svg" alt="" />
              <s.RedInfoBoxTexts>
                <span>매매가가 높으면 거래 성사가 어려울 수 있습니다.</span>
                <span>공시지가와 시세를 참고하여 입력해주세요.</span>
              </s.RedInfoBoxTexts>
            </s.RedInfoBox>
          </s.SectionContent>
        </s.Section>
        {/* 내 연락처 */}
        <s.Section>
          <s.SectionLabel>
            <span>내 연락처</span> <s.MustGreen>*필수</s.MustGreen>
          </s.SectionLabel>
          <s.WithLabelInputBox>
            <div>이름</div>
            <s.Input
              type="text"
              placeholder="홍길동"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </s.WithLabelInputBox>
          <s.WithLabelInputBox>
            <div>연락처</div>
            <s.Input
              type="text"
              placeholder="010-1234-5678"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <s.RedInfoBox>
              <img src="/asset/images/icon/red-warn.svg" alt="" />
              <s.RedInfoBoxTexts>
                <span>연락처 미입력 시 매물등록 및 노출이 불가합니다.</span>
                <span>거래를 위해 연락 가능한 번호를 입력해주세요.</span>
              </s.RedInfoBoxTexts>
            </s.RedInfoBox>
          </s.WithLabelInputBox>
        </s.Section>
      </s.ContentBox>
      <s.ButtonBox>
        <s.GreenBox
          style={{
            color: isButtonActive ? '#ffffff' : 'rgba(55, 56, 60, 0.16)',
            backgroundColor: isButtonActive ? '#1E9B6B' : '#F7F7F8',
          }}
          onClick={handleButtonClick}
        >
          수정완료
        </s.GreenBox>
      </s.ButtonBox>
    </s.Container>
  );
};
export default EditProperty;
